import { useEffect, useRef } from 'react'

// Sometimes we need to keep two refs on the same element to stay in sync. The effect of the hook makes sure that the ref created by this hook is updating the ref passed to this hook.
// @param {React.MutableRefObject<undefined>} ref - The ref that needs to stay in sync
// @returns a useRef instance
const useSyncedRef = ref => {
  // create a new ref
  const innerRef = useRef()

  // keep both refs in sync
  useEffect(() => {
    if (!ref) return

    // handle callback refs
    if (typeof ref === 'function') {
      ref(innerRef.current)
    } else { // handle object refs
      ref.current = innerRef.current
    }
  })

  // return the new ref
  return innerRef
}

export default useSyncedRef
