import { useEffect, useState } from 'react'

// Use this hook to use loading states from the vanilla JCBLAdapter which takes care of annotation CRUD
export default function useJCBLAdapterListener (closePanel) {
  const [loading, setLoading] = useState(true)
  const [createLoading, setCreateLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const [error, setError] = useState(null) // GET error
  const [CUDError, setCUDError] = useState(null) // Create-Update-Delete error

  const loadingListener = () => {
    setError(null)
    setLoading(true)
  }

  const errorListener = (e) => {
    setError(e.detail)
    setLoading(false)
  }

  const doneListener = () => {
    setError(null)
    setLoading(false)
  }

  const createLoadingListener = () => {
    setCUDError(null)
    setCreateLoading(true)
    closePanel()
  }

  const createErrorListener = (e) => {
    setCUDError(e.detail)
    setCreateLoading(false)
  }

  const createDoneListener = () => {
    setCUDError(null)
    setCreateLoading(false)
  }

  const deleteLoadingListener = () => {
    setCUDError(null)
    setDeleteLoading(true)
  }

  const deleteErrorListener = (e) => {
    setCUDError(e.detail)
    setDeleteLoading(false)
  }

  const deleteDoneListener = () => {
    setCUDError(null)
    setDeleteLoading(false)
  }

  const updateLoadingListener = () => {
    setCUDError(null)
    setUpdateLoading(true)
    closePanel()
  }

  const updateErrorListener = (e) => {
    setCUDError(e.detail)
    setUpdateLoading(false)
  }

  const updateDoneListener = () => {
    setCUDError(null)
    setUpdateLoading(false)
  }

  useEffect(() => {
    // Add loading listeners - Loading handlers are generic for all actions
    document.addEventListener('annotations-all-loading', loadingListener)
    document.addEventListener('annotations-create-loading', createLoadingListener)
    document.addEventListener('annotations-update-loading', updateLoadingListener)
    document.addEventListener('annotations-delete-loading', deleteLoadingListener)

    // Add error listeners - Errors are handled per action
    // TODO: ADD error handlers
    document.addEventListener('annotations-all-error', errorListener)
    document.addEventListener('annotations-create-error', createErrorListener)
    document.addEventListener('annotations-update-error', updateErrorListener)
    document.addEventListener('annotations-delete-error', deleteErrorListener)

    // Add done listeners - Done handlers are generic for all actions
    document.addEventListener('annotations-all-done', doneListener)
    document.addEventListener('annotations-create-done', createDoneListener)
    document.addEventListener('annotations-update-done', updateDoneListener)
    document.addEventListener('annotations-delete-done', deleteDoneListener)

    return () => {
      // Remove loading listeners
      document.removeEventListener('annotations-all-loading', loadingListener)
      document.removeEventListener('annotations-create-loading', createLoadingListener)
      document.removeEventListener('annotations-update-loading', updateDoneListener)
      document.removeEventListener('annotations-delete-loading', deleteLoadingListener)

      // Remove error listeners
      document.removeEventListener('annotations-all-error', errorListener)
      document.removeEventListener('annotations-create-error', createErrorListener)
      document.removeEventListener('annotations-update-error', updateErrorListener)
      document.removeEventListener('annotations-delete-error', deleteErrorListener)

      // Remove done listeners
      document.removeEventListener('annotations-all-done', doneListener)
      document.removeEventListener('annotations-create-done', createDoneListener)
      document.removeEventListener('annotations-update-done', updateDoneListener)
      document.removeEventListener('annotations-delete-done', deleteDoneListener)
    }
  }, [])

  return { loading, error, createLoading, CUDError, isDeleting: deleteLoading, isUpdating: updateLoading }
}
