
import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

export default class MemberManagementForm extends Component {
  init () {
    this.submitButtonTextContainer = this.element.querySelector('button[type="submit"] .link__span')
    this.submitSummaries = [null, null]
    this.initDetectNewMember()
    this.initDetectRemovals()
  }

  initDetectNewMember () {
    const newMemberField = this.element.querySelector('[name="email"]')
    const inviteMessage = this.element.getAttribute('data-invite-message')
    if (newMemberField && inviteMessage) {
      newMemberField.addEventListener('input', () => {
        const trimmer = l => s => s.length > l ? s.substring(0, l - 3) + '...' : s
        this.submitSummaries[0] = inviteMessage.replace('%(email)s', trimmer(20)(newMemberField.value))
        this.updateSubmitButtonText()
      })
    }
  }

  initDetectRemovals () {
    const removalFields = Array.from(this.element.querySelectorAll('input')).filter(e => e.name.endsWith('-DELETE'))
    const removeMessageSingular = this.element.getAttribute('data-remove-message-singular')
    const removeMessagePlural = this.element.getAttribute('data-remove-message-plural')
    let checkedRemovals = 0
    if (removeMessageSingular && removeMessagePlural) {
      removalFields.forEach(field => {
        field.addEventListener('change', () => {
          checkedRemovals = removalFields.filter(e => e.checked).length
          if (checkedRemovals === 1) {
            this.submitSummaries[1] = removeMessageSingular.replace('%(num)s', checkedRemovals.toString())
          } else {
            this.submitSummaries[1] = removeMessagePlural.replace('%(num)s', checkedRemovals.toString())
          }
          this.updateSubmitButtonText()
        })
      })
    }
  }

  updateSubmitButtonText () {
    const capFirst = s => s ? s[0].toUpperCase() + s.substring(1) : ''
    this.submitButtonTextContainer.innerText = capFirst(this.submitSummaries.filter(x => x).join(' and '))
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.form').forEach(element => {
  element.instance = element.instance || new MemberManagementForm(element)
}))
hydratorRegisterComponent('.member-management-form', MemberManagementForm)
