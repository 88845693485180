import Component from '../../../assets/scripts/modules/component'

export default class DateHistogramField extends Component {
  init () {
    this.lowerSlider = this.element.querySelector('[data-slider="lower"]')
    this.upperSlider = this.element.querySelector('[data-slider="upper"]')
    this.lowerVal = parseInt(this.lowerSlider.value)
    this.upperVal = parseInt(this.upperSlider.value)
    this.minLabel = this.element.querySelector('.date-histogram-field__min-label [data-max-label-text-container]')
    this.maxLabel = this.element.querySelector('.date-histogram-field__max-label [data-max-label-text-container]')
    this.minLabelText = Number(this.minLabel.innerText)
    this.maxLabelText = Number(this.maxLabel.innerText)
    this.minValue = Number(this.lowerSlider.min)
    this.maxValue = Number(this.lowerSlider.max)
    this.stepValue = Number(this.lowerSlider.step)
    this.lowerSliderName = this.lowerSlider.name
    this.upperSliderName = this.upperSlider.name

    this.dotColumns = this.element.querySelectorAll('.date-histogram-field__dot-column')
    this.markUninterfered()

    this.upperSlider.addEventListener('change', () => {
      this.markUserInterference()
    })

    this.upperSlider.addEventListener('input', (e) => {
      this.markUserInterference()
      this.update()

      // window.dispatchEvent(new CustomEvent('max-date-updated', { detail: e.target.value }))
    })

    this.lowerSlider.addEventListener('input', (e) => {
      const [lowerValue, upperValue] = [Number(this.lowerSlider.value), Number(this.upperSlider.value)]
      if (lowerValue + this.stepValue > this.maxValue) {
        this.lowerSlider.value = this.maxValue - this.stepValue
      }

      if (lowerValue + this.stepValue > upperValue) {
        this.upperSlider.value = lowerValue + this.stepValue
      }

      this.markUserInterference()
      this.update()

      // window.dispatchEvent(new CustomEvent('min-date-updated', { detail: e.target.value }))
    })

    this.update()
  }

  colorColumns () {
    this.dotColumns.forEach(column => {
      const colTimestamp = Number(column.dataset.timestamp)
      if (colTimestamp < this.lowerVal || colTimestamp >= this.upperVal) {
        column.classList.add('date-histogram-field__dot-column--black')
      } else {
        column.classList.remove('date-histogram-field__dot-column--black')
      }
    })
  }

  // Take action when the user did an action with the slider
  markUserInterference () {
    this.lowerSlider.name = this.lowerSliderName
    this.upperSlider.name = this.upperSliderName
  }

  markUninterfered () {
    // By changing the field name, submitting the form won't affect the date minimum and maximum
    // we change this back to the old name once the user interacted with the slider
    // If a custom date was present in the request, we don't change the name, because it
    // was already specified
    const customDatePresent = new URLSearchParams(window.location.search).has(this.lowerSliderName)
    if (!customDatePresent) {
      this.lowerSlider.name = `i${this.lowerSliderName}`
      this.upperSlider.name = `i${this.upperSliderName}`
    }
  }

  update () {
    this.lowerVal = Number(this.lowerSlider.value)
    this.upperVal = Number(this.upperSlider.value)

    if (this.upperVal - this.stepValue < this.minValue) {
      this.upperSlider.value = this.minValue + this.stepValue
    }

    if (this.lowerVal + this.stepValue > this.upperVal) {
      this.lowerSlider.value = this.upperVal - this.stepValue
    }

    this.minLabel.innerText = Math.max(this.minLabelText, new Date(this.lowerVal * 1000).getFullYear())
    this.maxLabel.innerText = Math.min(this.maxLabelText, new Date(this.upperVal * 1000).getFullYear())
    this.colorColumns()
    this.resize()
  }

  resize () {
    const rows = [...this.element.querySelectorAll('.date-histogram-field__dot-column')]
    const mostDots = Math.max(...rows.map(row => row.querySelectorAll('.date-histogram-field__dot').length))
    this.element.style.height = `${56 + (mostDots * 12)}px`
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.date-histogram-field').forEach(element => {
  element.instance = element.instance || new DateHistogramField(element)
}))
