import Component from '../../../assets/scripts/modules/component'

const MARGIN = 20
const FILL_PERCENTAGE = 10

const RESIZE_FACTOR = 0.7
const EXCLUDES = [
  '.menu-bar__logo',
  '.button--workspace',
  '.menu-bar__user-info',
  '.button--join',
  '.button--login',
  '.explore-header__text',
  '.explore-header__search'
]

function group (array, numGroups, sortFunction) {
  array.sort(sortFunction)
  const groupLength = Math.floor(array.length / numGroups)
  const groups = []
  for (let i = 0; i < numGroups; i++) {
    const start = groupLength * i
    const end = i === numGroups - 1 ? array.length : groupLength * (i + 1)
    groups.push(array.slice(start, end))
  }
  return groups
}

function shuffle (array) {
  let currentIndex = array.length; let randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }

  return array
}

function isValidRectangle (rectangle, boundingRectangle, minWidth = 0, minHeight = 0) {
  const [sx, sy, ex, ey] = rectangle
  const [bsx, bsy, bex, bey] = boundingRectangle
  return ex - sx + 1 >= minWidth && ey - sy + 1 >= minHeight && sx <= ex && sy <= ey && sx >= bsx && ex <= bex && sy >= bsy && ey <= bey
}

function subtractRectangle (rectangle, otherRectangle, minWidth, minHeight) {
  const [sx, sy, ex, ey] = rectangle
  const [osx, osy, oex, oey] = otherRectangle
  const bsx = Math.max(sx, Math.min(ex + 1, osx))
  const bex = Math.min(ex, Math.max(sx - 1, oex))
  const bsy = Math.max(sy, Math.min(ey + 1, osy))
  const bey = Math.min(ey, Math.max(sy - 1, oey))

  if (sx > oex || ex < osx || sy > oey || ey < osy) {
    // Optimalisation
    return [rectangle]
  }

  const newRectangles = [
    [sx, sy, ex, bsy - 1],
    [bex + 1, sy, ex, ey],
    [sx, bey + 1, ex, ey],
    [sx, sy, bsx - 1, ey]
  ]
  return newRectangles.filter(r => isValidRectangle(r, rectangle, minWidth, minHeight) && !isValidRectangle(r, otherRectangle))
}

function resizeAreaDebounce (receiver, minArea) {
  let area = window.innerWidth * window.innerHeight
  let testArea = minArea instanceof Function ? minArea() : minArea
  return (resizeEvent) => {
    const delta = Math.abs(area - window.innerWidth * window.innerHeight)
    if (delta < testArea) {
      return null
    }
    area = window.innerWidth * window.innerHeight
    testArea = minArea instanceof Function ? minArea() : minArea
    return receiver(resizeEvent)
  }
}

class CompositionBuilder {
  constructor (x, y, width, height, itemMinWidth, itemMinHeight, visibleWidth, visibleHeight) {
    this.rasters = [[x, y, width, height]]
    this.minWidth = itemMinWidth + MARGIN * 2
    this.minHeight = itemMinHeight + MARGIN * 2
    this.visibleWidth = visibleWidth
    this.visibleHeight = visibleHeight
  }

  addRectangle (sx, sy, ex, ey) {
    this.rasters = this.rasters.map(rectangle => subtractRectangle(rectangle, [sx, sy, ex, ey], this.minWidth, this.minHeight)).flat()
  }

  findSpot (width, height) {
    // Sort biggest first, because that is probably where the largest open space is
    this.rasters.sort(([sx1, sy1, ex1, ey1], [sx2, sy2, ex2, ey2]) => (Math.min(ex2, this.visibleWidth) - Math.max(0, sx2)) * (Math.min(this.visibleHeight, ey2) - Math.max(0, sy2)) - (Math.min(this.visibleWidth, ex1) - Math.max(0, sx1)) * (Math.min(this.visibleHeight, ey1) - Math.max(0, sy1)))

    for (const [sx, sy, ex, ey] of this.rasters) {
      const hSpace = ex - sx + 1
      const vSpace = ey - sy + 1
      if (hSpace >= width && vSpace >= height) {
        return [Math.floor(Math.random() * (hSpace - width)) + sx, Math.floor(Math.random() * (vSpace - height)) + sy]
      }
    }
  }
}

export default class ExploreHeader extends Component {
  init () {
    this.images = JSON.parse(this.element.querySelector('.explore-header__script').innerText).items
    this.smallestWidth = Math.max(40, Math.min(...this.images.map(i => i.width)))
    this.smallestHeight = Math.max(40, Math.min(...this.images.map(i => i.height)))
    this.build()

    const minResizeArea = () => window.innerWidth * 120

    window.addEventListener('resize', resizeAreaDebounce(() => {
      Array.from(this.element.querySelectorAll('.explore-header-composition-image')).forEach(e => e.remove())
      this.build()
    }, minResizeArea))
  }

  build () {
    const { width, height } = this.element.getBoundingClientRect()

    const images = [...this.images]
    const numGroups = 3
    const groups = group(images, numGroups, (a, b) => a.width * a.height - b.width * b.height)
    for (const group of groups) {
      shuffle(group)
    }
    const resizeFactor2 = Math.sqrt(window.innerWidth) / Math.sqrt(1280)

    const compositionBuilder = new CompositionBuilder(-100, -100, width + 100, height, this.smallestWidth, this.smallestHeight, width, height)
    EXCLUDES.forEach(selector => {
      Array.from(document.querySelectorAll(selector)).forEach(child => {
        const { x, y, width, height } = child.getBoundingClientRect()
        const [fullX, fullY] = [x + window.scrollX, y + window.scrollY]
        compositionBuilder.addRectangle(fullX - MARGIN, fullY - MARGIN, fullX + width + MARGIN, fullY + height + MARGIN) // eslint-disable-line smells/no-complex-string-concat
      })
    })

    let iterator = images.length
    // Prevent too much images from the same size being chosen by breaking when this happens more than the number below
    let breaksLeft = numGroups
    let fillPixelsLeft = width * height * FILL_PERCENTAGE / 100
    let atLeast = 4
    while (iterator > 0 && breaksLeft >= 0 && (fillPixelsLeft > 0 || atLeast > 0)) {
      iterator -= 1
      let image, width, height
      if (groups[iterator % numGroups].length) {
        image = groups[iterator % numGroups].pop()
        width = image.width * RESIZE_FACTOR * resizeFactor2
        height = image.height * RESIZE_FACTOR * resizeFactor2
      } else {
        width = 100
        height = 100
        breaksLeft -= 1
      }
      const spot = compositionBuilder.findSpot(width, height)
      if (!spot) {
        continue
      }
      const [x, y] = spot
      compositionBuilder.addRectangle(x - MARGIN, y - MARGIN, x + width - 1 + MARGIN, y + height - 1 + MARGIN)
      if (image) {
        fillPixelsLeft -= width * height
        atLeast -= 1
        const node = document.createElement('a')
        node.classList.add('explore-header-composition-image')
        node.href = image.href
        node.target = '_blank'
        node.style.top = y + 'px'
        node.style.left = x + 'px'
        const container = document.createElement('div')
        container.classList.add('explore-header-composition-image__container')
        const imgnode = document.createElement('img')
        imgnode.src = image.url
        imgnode.width = width
        imgnode.height = height
        imgnode.addEventListener('load', () => {
          node.classList.add('explore-header-composition-image--loaded')
        })
        container.appendChild(imgnode)
        node.appendChild(container)
        this.element.appendChild(node)
      }
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.explore-header').forEach(element => {
  element.instance = element.instance || new ExploreHeader(element)
}))
