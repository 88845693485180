
import Component from '../../../assets/scripts/modules/component'

export default class Form extends Component {
  init () {
    this.requiredCheckboxes = null
    this.submitButton = this.element.querySelector('button[type="submit"], input[type="submit"]')
    this.element.addEventListener('submit', (e) => this.handleSubmit(e))
    // this.initFormWithCheckbox() // FOR NOW WE DO NOT WANT THIS FUNCTIONALITY
  }

  handleSubmit (e) {
    this.preventMultipleSubmits()
  }

  preventMultipleSubmits () {
    this.submitButton.disabled = true
  }

  // Check if form has required checkbox inputs. Disable form submit if any is not checked.
  // FOR NOW WE DO NOT WANT THIS FUNCTIONALITY
  // initFormWithCheckbox () {
  //   const checkboxes = this.element.querySelectorAll('input[type="checkbox"]')

  //   this.requiredCheckboxes = new Array(checkboxes.length)

  //   checkboxes.forEach((checkbox, i) => {
  //     const checkboxIsRequiredHandler = () => {
  //       this.requiredCheckboxes[i] = checkbox.required && !checkbox.checked
  //       this.submitButton.disabled = this.requiredCheckboxes.every(val => !!val)
  //     }

  //     checkboxIsRequiredHandler() // First time check
  //     checkbox.addEventListener('change', checkboxIsRequiredHandler)
  //   })
  // }
}

window.addEventListener('init-load', () => document.querySelectorAll('.form').forEach(element => {
  element.instance = element.instance || new Form(element)
}))
