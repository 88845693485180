import Component from '../../../assets/scripts/modules/component'
import { hydrate, hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'
import { createFocusTrap } from 'focus-trap'

class ModalStackModal extends Component {
  init () {
    this.observe()
    this.title = null
    this.hasParent = false
    this.parentTitle = null
    this.activeElement = null
    this.focusTrap = null
    this.backButtonTemplate = null
    this.closeButtonTemplate = null
    this.errorStateTemplate = null
    this.loadingStateTemplate = null
    this.hasCloseButton = true
    this.initAccessibility()
  }

  initAccessibility () {
    this.focusTrap = createFocusTrap(this.element, {

      onActivate: () => {
        this.activeElement = document.activeElement
        const focusElement = this.element.querySelector('button, a')
        if (focusElement) {
          focusElement.focus()
          requestAnimationFrame(() => {
            focusElement.blur()
          })
        }
      },
      onDeactivate: () => {
        if (!this.activeElement) {
          return
        }

        this.activeElement.focus()
        this.activeElement = null
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false
    })
  }

  setHasParent (hasParent) {
    this.hasParent = hasParent
  }

  setParentTitle (title) {
    this.parentTitle = title
  }

  setHasCloseButton (hasCloseButton) {
    console.log('setHasCloseButton', hasCloseButton)
    this.hasCloseButton = hasCloseButton
  }

  setTitle (title) {
    this.title = title
  }

  setTemplates (templateLibrary) {
    this.backButtonTemplate = templateLibrary.backButtonTemplate
    this.closeButtonTemplate = templateLibrary.closeButtonTemplate
    this.errorStateTemplate = templateLibrary.errorStateTemplate
    this.loadingStateTemplate = templateLibrary.loadingStateTemplate
  }

  observe () {

  }

  async fetchContent (fetchUrl) {
    const modalContent = this.element.querySelector('.modal-stack-modal__content')
    modalContent.innerHTML = ''
    if (this.hasCloseButton) modalContent.prepend(this.makeCloseButton())
    if (this.hasParent) modalContent.prepend(this.makeBackButton())
    const loadingState = this.makeLoadingState()

    setTimeout(() => {
      this.focusTrap.activate()
    })

    modalContent.append(loadingState)
    hydrate(modalContent)

    let [json, text] = [{}, '']
    const response = await fetch(fetchUrl)

    if (response.status !== 200) {
      modalContent.removeChild(loadingState)
      modalContent.append(this.makeErrorState())
      return
    }

    if (fetchUrl.endsWith('.html')) {
      text = await response.text()
    } else {
      json = await response.json()
      text = json.html
    }

    if (json.title) {
      this.setTitle(json.title)
    }

    modalContent.removeChild(loadingState)
    const parser = new DOMParser()
    const html = parser.parseFromString(text, 'text/html')

    for (const child of Array.from(html.body.children)) {
      modalContent.appendChild(child)
    }

    hydrate(modalContent)
    const event = new CustomEvent('modal-stack-modal:loaded', { detail: { fromElement: this.element } })
    window.dispatchEvent(event)
  }

  makeBackButton () {
    const backButton = this.backButtonTemplate.content.cloneNode(true).firstElementChild
    const label = this.parentTitle ? `Back to ${this.parentTitle}` : 'Back'
    backButton.querySelector('.button__span').textContent = label
    backButton.addEventListener('click', () => {
      const event = new CustomEvent('modal-stack:back', { detail: { fromElement: this.element } })
      window.dispatchEvent(event)
      event.preventDefault()
      event.stopPropagation()
    })
    return backButton
  }

  makeCloseButton () {
    const closeButton = this.closeButtonTemplate.content.cloneNode(true).firstElementChild
    closeButton.addEventListener('click', event => {
      const closeEvent = new CustomEvent('modal-stack:close', { detail: { fromElement: this.element } })
      window.dispatchEvent(closeEvent)
      event.preventDefault()
      event.stopPropagation()
    })
    return closeButton
  }

  makeErrorState () {
    return this.errorStateTemplate.content.cloneNode(true).firstElementChild
  }

  makeLoadingState () {
    return this.loadingStateTemplate.content.cloneNode(true).firstElementChild
  }
}

class ModalStack extends Component {
  init () {
    this.observe()
    this.modalsContainer = this.element.querySelector('.modal-stack__modals')
    this.modalTemplate = this.element.querySelector('.modal-stack__modal-template')
    this.backButtonTemplate = this.element.querySelector('.modal-stack-back-button')
    this.closeButtonTemplate = this.element.querySelector('.modal-stack-close-button')
    this.errorStateTemplate = this.element.querySelector('.modal-stack-error-state')
    this.loadingStateTemplate = this.element.querySelector('.modal-stack-loading-state')
  }

  observe () {
    window.addEventListener('keydown', event => {
      if (this.modalsContainer.children.length === 0) return

      if (this.modalsContainer.children.length > 0 && event.key === 'Escape') {
        this.closeAll()
        event.stopPropagation()
        event.preventDefault()
      }

      if (this.modalsContainer.children.length > 0 && event.key === 'Backspace') {
        this.closeOne()
        event.stopPropagation()
        event.preventDefault()
      }
    })

    window.addEventListener('modal-stack:open', event => {
      const { fetchUrl, modalStackId, fromElement } = event.detail

      if (modalStackId === this.element.id || (!modalStackId && fromElement && this.element.contains(fromElement))) {
        const finalUrl = fetchUrl || fromElement.dataset.fetchUrl
        this.addModal(finalUrl)
      }
    })

    window.addEventListener('modal-stack:back', event => {
      const { fromElement, modalStackId } = event.detail

      if (modalStackId === this.element.id || (!modalStackId && fromElement && this.element.contains(fromElement))) {
        this.closeOne()
      }
    })

    window.addEventListener('modal-stack:close', event => {
      const { fromElement, modalStackId } = event.detail

      if (modalStackId === this.element.id || (!modalStackId && fromElement && this.element.contains(fromElement))) {
        this.closeAll()
      }
    })

    window.addEventListener('hashchange', () => {
      if (!window.location.hash.startsWith('#ms-active') && this.isActive()) {
        this.closeAll()
      }
    })
  }

  setStackBehaviour (node) {
    const classes = this.element.classList
    const numChildren = this.modalsContainer.children.length
    let [offsetTop, offsetLeft] = [0, 0]

    if (classes.contains('modal-stack--slidein-toleft')) {
      offsetLeft = 100
    } else if (classes.contains('modal-stack--slidein-toright')) {
      offsetLeft = -100
    } else if (classes.contains('modal-stack--slidein-totop')) {
      offsetTop = 100
    } else if (classes.contains('modal-stack--slidein-tobottom')) {
      offsetTop = -100
    }

    if (classes.contains('modal-stack--stack-toleft')) {
      offsetLeft += 100 * numChildren
    } else if (classes.contains('modal-stack--stack-toright')) {
      offsetLeft -= 100 * numChildren
    } else if (classes.contains('modal-stack--stack-tobottom')) {
      offsetTop -= 100 * numChildren
    } else if (classes.contains('modal-stack--stack-totop')) {
      offsetTop += 100 * numChildren
    }

    node.style.top = `${offsetTop}vh`
    node.style.left = `${offsetLeft}vw`

    return node
  }

  addModal (fetchUrl) {
    this.setActive()
    const node = this.modalTemplate.content.cloneNode(true).firstElementChild
    this.setStackBehaviour(node)
    const modalStackModal = new ModalStackModal(node)

    if (this.modalsContainer.children.length > 0) {
      const parentModal = this.modalsContainer.lastChild.instance
      modalStackModal.setParentTitle(parentModal.title)
      modalStackModal.setHasParent(true)
    }

    modalStackModal.setHasCloseButton(!this.element.classList.contains('modal-stack--no-close-button'))

    modalStackModal.setTemplates({
      backButtonTemplate: this.backButtonTemplate,
      closeButtonTemplate: this.closeButtonTemplate,
      errorStateTemplate: this.errorStateTemplate,
      loadingStateTemplate: this.loadingStateTemplate
    })
    modalStackModal.fetchContent(fetchUrl)
    node.instance = modalStackModal
    this.modalsContainer.appendChild(node)

    // if (window.location.hash !== '#ms-active') {
    //   window.history.pushState({}, '', '#ms-active')
    // }

    this.moveToFront()
  }

  closeOne () {
    const numChildren = this.modalsContainer.children.length
    if (!numChildren) return
    if (numChildren === 1) return this.closeAll()

    this.moveToFront(-1)

    setTimeout(() => {
      if (this.modalsContainer.children.length >= numChildren) {
        this.modalsContainer.removeChild(this.modalsContainer.lastChild)
      }
    }, 300)
  }

  closeAll () {
    Array.from(this.modalsContainer.children).slice(0, -1).forEach(child => {
      child.style.visibility = 'hidden'
      child.instance.focusTrap.deactivate()
    })
    this.moveToFront(0, true)
    setTimeout(() => { this.modalsContainer.innerHTML = '' }, 600)

    this.setInactive()
  }

  moveToFront (offset = 0, ignoreSlideIn = false) {
    const numChildren = this.modalsContainer.children.length
    const classes = this.element.classList
    let [offsetTop, offsetLeft] = [0, 0]

    if (ignoreSlideIn) {
      // do nothing here
    } else if (classes.contains('modal-stack--slidein-toleft')) {
      offsetLeft = -100
    } else if (classes.contains('modal-stack--slidein-toright')) {
      offsetLeft = 100
    } else if (classes.contains('modal-stack--slidein-totop')) {
      offsetTop = -100
    } else if (classes.contains('modal-stack--slidein-tobottom')) {
      offsetTop = 100
    }

    if (classes.contains('modal-stack--stack-toleft')) {
      offsetLeft -= 100 * (numChildren - 1 + offset)
    } else if (classes.contains('modal-stack--stack-toright')) {
      offsetLeft += 100 * (numChildren - 1 + offset)
    } else if (classes.contains('modal-stack--stack-tobottom')) {
      offsetTop += 100 * (numChildren - 1 + offset)
    } else if (classes.contains('modal-stack--stack-totop')) {
      offsetTop -= 100 * (numChildren - 1 + offset)
    }

    this.element.style.transform = `translate(${offsetLeft}vw, ${offsetTop}vh)`
  }

  setActive () {
    this.element.classList.add('modal-stack--active')
  }

  setInactive () {
    this.element.classList.remove('modal-stack--active')

    // if (window.location.hash === '#ms-active') {
    //   window.history.pushState({}, '', '#')
    // }
  }

  isActive () {
    return this.element.classList.contains('modal-stack--active')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.modal-stack').forEach(element => {
  element.instance = element.instance || new ModalStack(element)
}))

hydratorRegisterComponent('.modal-stack__modal', ModalStackModal)
hydratorRegisterComponent('.modal-stack', ModalStack)
