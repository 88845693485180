import LocalStorageAdapter from '../../scripts/plugins/mirador-annotations/LocalStorageAdapter'

import JCBLAdapter from '../../../components/structures/mirador/components/JCBLAdapter'
import Menu from '../../../components/structures/mirador/components/Menu'
import NavigationPanel from '../../../components/structures/mirador/components/NavigationPanel'

export default async function initMiradorAnnotationViewer (selector) {
  const miradorContainer = document.getElementById(selector)

  if (!miradorContainer) {
    return
  }

  const Mirador = (await import('../plugins/mirador')).default()
  const miradorAnnotationPlugin = (await import('../plugins/mirador-annotation-plugin')).default()
  const miradorImageToolsPlugin = (await import('../plugins/mirador-image-tools-plugin')).default()

  const annotationAdapter = {
    local: (canvasId) => new LocalStorageAdapter(`localStorage://?canvasId=${canvasId}`),
    server: (canvasId) => new JCBLAdapter(canvasId)
  }[miradorContainer.getAttribute('data-annotation-adapter') || 'server']

  const config = {
    id: selector,
    windows: [
      {
        imageToolsEnabled: false,
        loadedManifest: miradorContainer.getAttribute('data-manifest-url') || 'https://iiif.harvardartmuseums.org/manifests/object/299843'
      }
    ],
    annotation: {
      adapter: annotationAdapter,
      defaults: {
        activeTool: 'rectangle',
        strokeColor: '#D0021B',
        strokeWidth: 10
      }
    },
    window: {
      defaultSideBarPanel: 'annotations'
    },
    workspaceControlPanel: {
      enabled: false
    },
    // More info about theming see https://github.com/ProjectMirador/mirador/wiki/M3-Theming-Mirador
    themes: {
      light: {
        palette: {
          primary: {
            main: '#e88f8f'
          }
        },
        typography: {
          fontFamily: ['Open Sans']
        }
      }
    },
    windowObjects: {}
  }

  const params = new URLSearchParams(window.location.search)
  const menuInitialState = {}

  if (params.has('canvasId')) {
    config.windows[0].canvasId = params.get('canvasId')
  }
  if (params.has('sideBarPanel')) {
    // config.window.sideBarPanel = params.get('sideBarPanel')
    // config.window.sideBarOpen = true
    if (params.get('sideBarPanel') === 'annotations') {
      menuInitialState.annotationsPanelOpen = true
    }
  }

  const miradorInstance = Mirador.viewer(config, [
    ...miradorAnnotationPlugin,
    ...miradorImageToolsPlugin
  ])

  const { store } = miradorInstance
  const state = store.getState()
  const windowId = state.workspace.windowIds[0]

  Menu(document.querySelector('.js-mirador-menu'), store, windowId, menuInitialState)
  NavigationPanel(document.querySelector('.js-mirador-nav'), store, windowId)
}
