
const componentPatterns = {}
const callables = []

const hydrate = (container) => {
  Object.entries(componentPatterns).forEach(([qs, Component]) => {
    Array.from(container.querySelectorAll(qs)).forEach(element => {
      element.instance = element.instance || new Component(element)
    })
  })
  callables.forEach(callable => callable(container))
}

const hydratorRegisterCallable = (callable) => {
  callables.push(callable)
}

const hydratorRegisterComponent = (querySelector, componentClass) => {
  componentPatterns[querySelector] = componentClass
}

export { hydrate, hydratorRegisterCallable, hydratorRegisterComponent }
