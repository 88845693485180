import { useEffect, useMemo, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import React from 'react'
import { useOnClickOutside } from '../../../../assets/scripts/utilities/react/hooks/useClickOutside'
import useFocusLoop from '../../../../assets/scripts/utilities/react/hooks/useFocusLoop'
import { Button } from '../../../atoms/button/react/Button'
import IconButton from '../../../atoms/icon-button/react/IconButton'

const cancelDefault = { title: 'Cancel', onClick: () => { } }
const submitDefault = { title: 'Submit', onClick: () => { } }

const Modal = ({
  classes = '',
  title = '',
  actions = {},
  isOpen = false,
  onOpen = () => { },
  onClose,
  closeModal,
  toggleRef = null,
  children
}) => {
  // Default modal actions
  const { title: submitTitle, ...submitProps } = actions.submit ?? submitDefault
  const { title: cancelTitle, ...cancelProps } = actions.cancel ?? cancelDefault

  const closeHandler = onClose || closeModal

  // Container ref
  const containerRef = useRef(null)

  // Escape key listener
  const extraListeners = useMemo(() => new Map([
    [27, closeHandler]
  ]), [closeHandler])

  // Create focus trap for modal
  const modalRef = useFocusLoop(isOpen, extraListeners, toggleRef)

  useOnClickOutside(containerRef, closeHandler)

  useEffect(() => {
    isOpen && onOpen()
  }, [isOpen])

  // if (!isOpen) return null

  return (
    <CSSTransition nodeRef={modalRef} in={isOpen} timeout={300} unmountOnExit classNames="react-modal-transition">
      <div ref={modalRef} className={`react-modal modal ${classes}`} aria-hidden="false">
        <div className="modal__overlay" tabIndex="-1">
          <div ref={containerRef} className="modal__container" role="dialog" aria-modal="true" aria-labelledby="react-modal-title">
            <div className="modal__wrapper">
              <IconButton classes='modal__close' icon='close' aria-label="Close modal" onClick={closeHandler} />

              <header className="modal__header">
                <h2 className="modal__title">
                  {title}
                </h2>
              </header>

              {children && (
                <div className="modal__content">
                  {children}
                </div>
              )}

              {actions && (actions.submit || actions.cancel) && (
                <div className="modal__actions">
                  {actions.submit && <Button variant='primary' title={submitTitle} {...submitProps} />}
                  {actions.cancel && <Button variant='tertiary' title={cancelTitle} {...cancelProps} />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Modal
