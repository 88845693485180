
import Component from '../../../assets/scripts/modules/component'

const OBJECT_HEIGHT = 280
const TOP_PADDING = 24
const BOTTOM_PADDING = 24
// eslint-disable-next-line smells/no-complex-string-concat
const CLOSED_HEIGHT = OBJECT_HEIGHT + TOP_PADDING + BOTTOM_PADDING

export default class ObjectAnnotationOverview extends Component {
  init () {
    this.initExpandSupport()
  }

  initExpandSupport () {
    const expandButton = this.element.querySelector('.object-annotation-overview__toggle-open-btn')
    const expandButtonTextContainer = expandButton.querySelector('.button__span')
    const pictureContainer = this.element.querySelector('.object-annotation-overview__picture-container')

    if (!expandButton || !expandButtonTextContainer) return

    const seeMoreLabel = expandButtonTextContainer.innerText
    const seeLessLabel = this.element.getAttribute('data-label-less')
    const isExpandable = true
    const expandHeight = this.element.offsetHeight
    const closedHeight = pictureContainer ? pictureContainer.offsetHeight + 48 : CLOSED_HEIGHT

    let isExpanded = false

    function expand () {
      this.element.classList.add('object-annotation-overview--active')
      expandButtonTextContainer.innerText = seeLessLabel
      this.element.style.maxHeight = `${expandHeight}px`
    }

    function abbreviate () {
      this.element.classList.remove('object-annotation-overview--active')
      expandButtonTextContainer.innerText = seeMoreLabel
      this.element.style.maxHeight = `${closedHeight}px`
    }

    function apply () {
      (isExpanded ? expand.bind(this) : abbreviate.bind(this))()
    }

    if (isExpandable) {
      expandButton.style.display = 'block'
      expandButton.addEventListener('click', () => {
        isExpanded = !isExpanded
        apply.bind(this)()
      })

      apply.bind(this)()
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.object-annotation-overview').forEach(element => {
  element.instance = element.instance || new ObjectAnnotationOverview(element)
}))
