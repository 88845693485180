/* eslint-disable camelcase */

function mapProjectStateGET (state) {
  if (!state) return null

  if (!state.sections || !state.sections.length) return state

  const sections = state.sections.map(section => {
    let objects = []

    if (section.project_objects && !!section.project_objects.length) {
      objects = section.project_objects.map(object => ({
        ...object,
        id: object.uuid, // Map uuid to id field since DND library works with a unique 'id' field
        serverId: object.id, // Store the original ID field
        manifest: {
          ...object.manifest,
          variant: object.manifest.variant || 'object', // API doesn't provide variant, defaulting here for now
          subtitle: object.manifest.super_title, // TODO: BE should send correct format
          description: object.manifest.sub_title, // TODO: BE should send correct format
          image: { images: { ...object.manifest.image } } // TODO: BE should send correct format
        }
      }))
    }

    return { ...section, id: section.uuid, serverId: section.id, objects }
  })

  return { ...state, sections }
}

function mapProjectStatePATCH (state) {
  if (!state.sections || !state.sections.length) return state

  const sections = state.sections.map(section => {
    let result = {
      title: section.title,
      description: section.description,
      project: section.project,
      uuid: section.id // Map id back to uuid
    }

    // If user is editing the object in a section, for example drag/drop or making annotation public/private
    if (section.objects) {
      const project_objects = section.objects.map(object => {
        const result = {
          uuid: object.id, // Map id back to uuid
          manifest: {
            id: object.manifest.id,
            url: object.manifest.url
          },
          annotations: object.annotations
        }

        return result
      })

      result = { ...result, project_objects }
    }

    // Remove previously mapped and obsolete entries
    delete result.id
    delete result.serverId
    delete result.objects

    return result
  })

  const payload = { ...state, sections }

  console.log('mapped payload :::: ', payload)

  return payload
}

export { mapProjectStateGET, mapProjectStatePATCH }
