import React from 'react'

import useHover from '../../../../assets/scripts/utilities/react/hooks/useHover'

const IconButtonLink = React.forwardRef(({ classes = '', icon, altIcon, title, variant = '', size = 'default', ...linkProps }, ref) => {
  const [hoverRef, hovering] = useHover(ref)

  const getIcon = () => {
    const Icon = require(`../../../../assets/icons/custom/${hovering && altIcon ? altIcon : icon}.svg`).default

    return <Icon />
  }

  return (
    <a ref={hoverRef} className={`icon-button ${variant ? 'icon-button--' + variant : ''} ${size !== 'default' ? 'icon-button--' + size : ''} ${classes}`} {...linkProps}>
      <span className="icon-button__icon">{getIcon()}</span>
      <span className="icon-button__title" hidden="screen">{title}</span>
    </a>
  )
})

IconButtonLink.displayName = 'IconButtonLink'

export default IconButtonLink
