import React, { forwardRef } from 'react'

import WarningIcon from '../../../../assets/icons/custom/warning.svg'

const JCBErrorMessage = forwardRef(({ message, noMargin }, ref) => {
  return <span ref={ref} className={`JCBErrorMessage ${noMargin ? 'JCBErrorMessage--nomargin' : ''}`}>
    <span className='JCBErrorMessage__Icon'><WarningIcon /></span>
    <span className='JCBErrorMessage__Text'>{message}</span>
  </span>
})

JCBErrorMessage.displayName = 'JCBErrorMessage'

export default JCBErrorMessage
