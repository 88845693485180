import { useEffect } from 'react'

// Hook
function useOnClickOutside (ref, handler) {
  useEffect(() => {
    const listener = evt => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(evt.target)) return
      handler(evt)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
  }, [ref, handler])
}

function useOnClickOutsideMultiple (refs, handler) {
  useEffect(() => {
    const listener = evt => {
      let shouldHandle = true

      for (const ref of refs) {
        if (!ref.current || ref.current.contains(evt.target)) {
          shouldHandle = false
          break
        }
      }
      // Do nothing if clicking ref's element or descendent elements
      shouldHandle && handler(evt)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
  }, [refs, handler])
}

export { useOnClickOutside, useOnClickOutsideMultiple }
