import Component from '../../../assets/scripts/modules/component'

export default class FooterBarComponent extends Component {
  init () {
    const chatName = this.element.dataset.chatname

    if (!chatName) {
      return
    }

    // Get agent availability
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `https://ccl.cnvinfo.nl/chat_beschikbaarheid/?Project=${chatName}`)
    xhr.send()

    xhr.onload = () => {
      if (xhr.status !== 200) {
        console.error(`Error ${xhr.status}. Could not obtain chat availability information.`)
      } else {
        // If the chat has agents, we show the button to start it
        const result = JSON.parse(xhr.response)
        if (result && result.Status === 'Available') {
          this.showChatButton()
        }
      }
    }
  }

  showChatButton () {
    this.element.classList.add('footer-bar--can-chat')
    const chatWidget = this.element.querySelector('[data-role="chat-widget"]')

    const button = chatWidget.querySelector('button')

    button.addEventListener('click', (event) => {
      // Prevents page refresh from anchor
      const parser = new URL(window.location)

      // base.html checks this and adds script tag
      // Needed cause loading script from JS is prevented in FF
      parser.searchParams.set('chat_requested', 'true')
      window.location = parser.href
      return false
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.footer-bar').forEach(element => {
  element.instance = element.instance || new FooterBarComponent(element)
}))
