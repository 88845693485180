
import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

// We can't directly modify a FileList (such as input.files).
// So, removing selected uploads one-by-one is impossible. Or is it?
// We can replace the FileList with another FileList, like that of a different DataTransfer object.
const removeItemFromFileList = function (fileList, name) {
  let files = [...fileList]
  files = files.filter(file => file.name !== name)

  if (files.length === fileList.length) {
    return fileList // No change
  }

  // NOTE: Does Safari on iOS support DataTransfer.{files|items}? Can't test right now -EKL
  const transfer = new DataTransfer()
  files.forEach(file => transfer.items.add(file))

  return transfer.files
}

const prettyPrintFileSize = bytes => bytes > 1024000 ? `${(bytes / 1024 / 1000).toFixed(2).toString()} MB` : `${(bytes / 1024).toFixed(0).toString()} kB`

// TODO: Add drag n drop

export default class FileFieldComponent extends Component {
  init () {
    this.input = this.element.querySelector('.file-field__input')
    this.previews = this.element.querySelector('.file-field__previews')
    this.template = this.element.querySelector('.file-field__preview-template')
    this.buttonAdd = this.element.querySelector('.button--add')
    this.buttonClear = this.element.querySelector('.button--clear')

    if (!this.input || !this.previews || !this.template || !this.buttonAdd || !this.buttonClear) {
      return
    }

    this.input.addEventListener('change', event => this.populatePreviews(event))
    this.buttonClear.addEventListener('click', event => this.resetFileField(event))
  }

  populatePreviews (event) {
    this.previews.innerHTML = ''

    const files = [...this.input.files]

    if (!files.length) {
      this.resetFileField(event)
      return
    }

    files.forEach(file => this.addPreview(file))
    this.element.classList.add('file-field--has-files')
  }

  addPreview (file) {
    const clone = this.template.content.cloneNode(true)

    const preview = clone.querySelector('.file-field__preview')
    const image = clone.querySelector('.file-field__preview-image')
    const filename = clone.querySelector('.file-field__preview-filename')
    const filesize = clone.querySelector('.file-field__preview-filesize')
    const resolution = clone.querySelector('.file-field__preview-resolution')

    preview.dataset.name = file.name

    // if (!['.jpg', '.jpeg', '.png'].some(ext => file.name.endsWith(ext))) {
    //   return
    // }

    image.src = URL.createObjectURL(file)
    image.onload = () => {
      URL.revokeObjectURL(image.src) // Prevent memory leak

      if (resolution) {
        resolution.innerText = `${image.naturalWidth}×${image.naturalHeight}px`
      }
    }

    if (filename) {
      filename.innerText = file.name.split('\\')
    }

    if (filesize) {
      filesize.innerText = `(${prettyPrintFileSize(file.size)})`
    }

    this.previews.appendChild(clone)

    preview.addEventListener('click', event => this.removeFile(preview))
  }

  removeFile (preview) {
    if (!preview) {
      return
    }

    this.input.files = removeItemFromFileList(this.input.files, preview.dataset.name)
    preview.parentNode.removeChild(preview)

    if (!this.input.files.length) {
      this.resetFileField()
    }
  }

  resetFileField (event) {
    if (event) {
      event.preventDefault()
      event.target.blur()
    }

    this.input.value = ''
    this.previews.innerHTML = ''
    this.element.classList.remove('file-field--has-files')
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.file-field')) {
    element.instance = element.instance || new FileFieldComponent(element)
  }
})

hydratorRegisterComponent('.file-field', FileFieldComponent)
