
import Swiper from 'swiper'

import Component from '../../../assets/scripts/modules/component'
import { hydrate } from '../../../assets/scripts/utilities/hydrator'

export default class ArticlePhotoCarousel extends Component {
  init () {
    this.container = this.element.querySelector('.swiper-container')

    if (this.container) {
      this.liveregion = document.createElement('div')
      this.liveregion.setAttribute('aria-live', 'polite')
      this.liveregion.setAttribute('aria-atomic', 'true')
      this.liveregion.setAttribute('class', 'liveregion visuallyhidden')
      this.container.appendChild(this.liveregion)
      this.liveregion.style.position = 'absolute'
      this.liveregion.style.opacity = '0'

      const swiperOptions = {
        loop: true,
        spaceBetween: 20,
        navigation: {
          nextEl: this.element.querySelector('.swiper-button-next'),
          prevEl: this.element.querySelector('.swiper-button-prev')
        },
        on: {
          init: () => {
            hydrate(this.element)
          }
        }
      }

      this.swiperWrapper = new Swiper(this.container, swiperOptions)
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.article-photo-carousel').forEach(element => {
  element.instance = element.instance || new ArticlePhotoCarousel(element)
}))
