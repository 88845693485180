import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'

export default class FilterList extends Component {
  init () {
    this.initExpandSupport()
    this.initRadioDeselectSupport()
  }

  initExpandSupport () {
    const filterButtons = this.element.querySelectorAll('.filter-list__filter-button')
    const filterContainer = this.element.querySelector('.filter-list__filters-container')
    const expandButton = this.element.querySelector('.filter-list__expand-button')
    const expandButtonTextContainer = expandButton.querySelector('.link__span')
    const seeMoreLabel = expandButtonTextContainer.innerText
    const seeLessLabel = this.element.getAttribute('data-label-less')
    const isExpandable = filterButtons.length > 5
    let expandHeight = filterContainer.offsetHeight
    let isExpanded = this.element.querySelector('.filter-list__filter-button:nth-child(n + 6) input:checked')

    function expand () {
      this.element.classList.add('filter-list--open')
      expandButtonTextContainer.innerText = seeLessLabel
      filterContainer.style.maxHeight = `${expandHeight}px`
    }

    function abbreviate () {
      const abbreviateheight = filterButtons[5].offsetTop - filterContainer.offsetTop
      expandHeight = filterContainer.offsetHeight
      this.element.classList.remove('filter-list--open')
      expandButtonTextContainer.innerText = seeMoreLabel
      filterContainer.style.maxHeight = `${abbreviateheight - 8}px`
    }

    function apply () {
      (isExpanded ? expand.bind(this) : abbreviate.bind(this))()
    }

    if (isExpandable) {
      expandButton.style.display = 'flex'
      expandButton.addEventListener('click', () => {
        isExpanded = !isExpanded
        apply.bind(this)()
      })

      apply.bind(this)()
    }
  }

  // Adds support for radio buttons that they are deselected when clicked when active.
  initRadioDeselectSupport () {
    const radios = Array.from(this.element.querySelectorAll('input[type=radio]'))
    let activeRadio = radios.reduce((prev, cur, index) => cur.checked ? index : prev, -1)
    radios.forEach((radio, index) => {
      const label = radio.nextElementSibling
      label.addEventListener('click', (event) => {
        if (activeRadio === index) {
          activeRadio = -1
          event.preventDefault()
          radio.checked = false
          fireCustomEvent('change', {}, false, false, radio)
        } else {
          activeRadio = index
        }
      })
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.filter-list').forEach(element => {
  element.instance = element.instance || new FilterList(element)
}))
