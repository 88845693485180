import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

class Button extends Component {
  init () {
    this.detectEventSender()
  }

  detectEventSender () {
    this.element.addEventListener('click', event => {
      const href = this.element.getAttribute('href')

      if (href && href.match(/^#[\w-]+:[\w-]+$/)) {
        const [component, action] = href.split('#')[1].split(':')
        const modalStackId = this.element.dataset.modalStackId
        const newEvent = new CustomEvent(`${component}:${action}`, { detail: { fromElement: this.element, modalStackId } })
        window.dispatchEvent(newEvent)
        event.preventDefault()
        event.stopPropagation()
      }
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.button').forEach(element => {
  element.instance = element.instance || new Button(element)
}))

hydratorRegisterComponent('.button', Button)
