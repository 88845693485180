import MicroModal from '../../../assets/scripts/plugins/micro-modal'

import { hydrate, hydratorRegisterCallable } from '../../../assets/scripts/utilities/hydrator'

const AJAX_OPTIONS = {
  mode: 'same-origin',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
}

const RESPONSE_TYPES = {
  error: 'error', // In case something went wrong
  errorHtml: 'errorHtml', // In case something went wrong but we got a html response
  html: 'html', // The response returned some html to render
  html_and_refresh: 'html_and_refresh', // The response returned some html to render and wants to refresh the page
  redirect: 'redirect', // The response returned a URL to redirect to
  refresh: 'refresh', // The response would like to refresh the current page
  value: 'value' // The response returned a value for the requester
}

class AjaxModal {
  constructor (element) {
    this.element = element
    this.requestTypes = window.ajaxModalRequestTypes || {}
    this.contentContainer = this.element.querySelector('[data-ajax-modal-content]')
    this.titleContainer = document.getElementById('ajax-modal-title')
    this.errorContainer = this.element.querySelector('.ajax-modal__error-message')

    // Due to how micro modal works, we need to reinit it when new ajax content is loaded, but this will create additional event listeners to already existing activators. Therefor we fix this for now with a busy lock
    this.busy = false
    this.closeTriggers = []

    function microModalInit () {
      MicroModal.init({
        onShow: async (modal, activeElement, event) => {
          if (this.busy) {
            return
          }
          if (event.currentTarget?.getAttribute('data-ajax-modal-request-type')) {
            if (event) {
              event.preventDefault()
            }
            return this.handle(event.currentTarget.getAttribute('data-ajax-modal-request-type'), event.currentTarget)
          }
        },
        onClose: () => {
          this.closeTriggers.forEach(fn => fn())
          this.closeTriggers = []
        }
      })
    }

    const microModalShow = async () => {
      try {
        MicroModal.show('ajax-modal')
        await this.handle(searchQuery.get('modal-flow'), 'QUERY')
      } catch (e) {
        console.log('Ajax Modal -- microModalShow ERROR ::: ', e)
      }
    }

    hydratorRegisterCallable((container) => {
      microModalInit.bind(this)()
    })

    microModalInit.bind(this)()

    const searchQuery = new URLSearchParams(window.location.search)
    if (searchQuery.has('modal-flow') && searchQuery.get('modal-flow') in this.requestTypes) {
      microModalShow()

      // MicroModal.show('ajax-modal').then(() => {
      //   this.handle(searchQuery.get('modal-flow'), 'QUERY').catch(console.log)
      // })
    }
  }

  setContentError (error) {
    this.element.classList[error ? 'add' : 'remove']('ajax-modal--error')
    this.errorContainer.innerText = error
  }

  setLoading () {
    this.contentContainer.innerHTML = ''
  }

  setText (text) {
    this.contentContainer.innerHTML = `<p>${text}</p>`
  }

  setTitle (title) {
    this.titleContainer.innerText = title
  }

  async handle (requestType, contextElement) {
    if (!(requestType in this.requestTypes)) {
      throw new Error(`Unknown request type received: ${requestType}.`)
    }

    const { title, urlPattern } = this.requestTypes[requestType]
    const url = this._buildUrlPattern(urlPattern, contextElement)
    const handlerInfo = new HandlerInfo(url)

    let lastResponse

    this.setContentError('')

    while (handlerInfo.hasPendingRequests()) {
      this.busy = true
      this.setTitle(title)
      this.setLoading()
      let responseType, content

      try {
        [responseType, content] = await this._retrieveContent(handlerInfo)
      } catch (error) {
        this.setTitle('Something went wrong :-(')
        console.log(error)
        return
      }
      this.busy = false

      if (responseType === RESPONSE_TYPES.html || responseType === RESPONSE_TYPES.html_and_refresh) {
        const { html, title: intermediateTitle, contentError } = content
        if (intermediateTitle) {
          this.setTitle(intermediateTitle)
        }
        if (contentError) {
          this.setContentError(contentError)
        } else {
          this.setContentError('')
        }
        await this._installContent(html, handlerInfo)
        // this.setTitle(title)
      } else if (responseType === RESPONSE_TYPES.errorHtml) {
        this.setTitle('Something went wrong :-(')
        await this._installContent(content, handlerInfo)
        return
      } else if (responseType === RESPONSE_TYPES.error) {
        this.setTitle('Something went wrong :-(')
        this.setText('Please try again later.')
      } else if (responseType === RESPONSE_TYPES.redirect) {
        this.closeTriggers.push(() => {
          setTimeout(() => {
            MicroModal.show('ajax-modal')
            this.setTitle('One moment please')
            this.setText('We are redirecting you.')
            window.location.href = content
          }, 1)
        })
      } else if (responseType === RESPONSE_TYPES.refresh || responseType === RESPONSE_TYPES.html_and_refresh) {
        this.closeTriggers.push(() => {
          setTimeout(() => {
            MicroModal.show('ajax-modal')
            this.setTitle('One moment please')
            this.setText('We will reload the current page.')
            window.location.reload()
          }, 1)
        })
      }

      lastResponse = [responseType, content]
    }

    if (lastResponse) {
      const [responseType, response] = lastResponse
      if (responseType === RESPONSE_TYPES.redirect) {
        this.setTitle('Redirecting...')
        window.location.href = response
      } else if (responseType === RESPONSE_TYPES.refresh || responseType === RESPONSE_TYPES.html_and_refresh) {
        if (responseType === RESPONSE_TYPES.html_and_refresh) {
          this.closeTriggers.push(() => {
            setTimeout(() => {
              MicroModal.show('ajax-modal')
              this.setTitle('One moment please')
              this.setText('We will reload the current page.')
              window.location.reload()
            }, 1)
          })
        } else {
          this.setTitle('One moment please')
          this.setText('We will reload the current page.')
          window.location.reload()
        }
      } else if (responseType === RESPONSE_TYPES.value) {
        MicroModal.close('ajax-modal')
        return response
      }
    }
  }

  _buildUrlPattern (urlPattern, contextElement) {
    function keyGetter (key) {
      let value

      if (contextElement === 'QUERY') {
        value = new URLSearchParams(window.location.search).get(key)
      } else {
        value = contextElement.getAttribute(`data-ajax-modal-${key}`)
      }

      return value || '?'
    }

    return urlPattern.replace(
      /<([\w-]+)>/g,
      (g0, g1) => keyGetter(g1)
    )
  }

  async _installContent (content, handlerInfo) {
    this.contentContainer.innerHTML = content
    hydrate(this.contentContainer)
    return new Promise((resolve, reject) => {
      const form = this.contentContainer.querySelector('form')
      if (!form) {
        resolve()
        return
      }
      form.addEventListener('submit', (event) => {
        event.preventDefault()

        this.setContentError('')
        const method = (form.getAttribute('method') || 'GET').toUpperCase()
        const options = { method }
        const formData = new FormData(form)
        let [url] = handlerInfo.getCurrent()
        if (event.submitter && event.submitter.getAttribute('name')) {
          formData.append(event.submitter.getAttribute('name'), event.submitter.getAttribute('value'))
        }
        if (method === 'POST') {
          options.body = formData
        } else {
          url = `${url}?${new URLSearchParams(formData).toString()}`
        }
        handlerInfo.push(url, options)
        resolve()
      })
    })
  }

  async _retrieveContent (handlerInfo) {
    const [url, extraOptions] = handlerInfo.pop()
    const options = { ...AJAX_OPTIONS, ...extraOptions }
    const response = await fetch(url, options)
    let json
    try {
      json = await response.json()
    } catch (e) {
      json = {}
    }

    if (response.status === 403 && json.redirect) {
      if (json.action !== 'replace') {
        handlerInfo.push(url)
      }
      handlerInfo.push(json.redirect)
      return this._retrieveContent(handlerInfo)
    }

    if (response.redirected) {
      handlerInfo.getCurrent()[0] = response.url
    }

    if (response.status === 200 && json.refresh) {
      if (json.html) {
        return [
          RESPONSE_TYPES.html_and_refresh,
          { html: json.html, title: json.title, contentError: json.content_error }
        ]
      }
      return [RESPONSE_TYPES.refresh, null]
    }

    if (response.status === 200 && json.redirect) {
      return [RESPONSE_TYPES.redirect, json.redirect]
    }

    if (response.status === 200 && 'value' in json) {
      return [RESPONSE_TYPES.value, json.value]
    }

    if (response.status !== 200 && json.html) {
      return [RESPONSE_TYPES.errorHtml, json.html]
    }

    if (response.status !== 200) {
      return [RESPONSE_TYPES.error, json.message]
    }

    if (response.status !== 200 || !json.html) {
      throw new Error(`Got invalid response: ${response.status} with HTML: ${json.html}.`)
    }

    return [RESPONSE_TYPES.html, { html: json.html, title: json.title, contentError: json.content_error }]
  }
}

class HandlerInfo {
  constructor (url, options = {}) {
    this.stack = [new RequestData(url, options)]
    this.current = null
  }

  getCurrent () {
    return this.current
  }

  hasPendingRequests () {
    return this.stack.length > 0
  }

  pop () {
    const { url, options } = this.stack.pop()
    this.current = [url, options]
    return this.current
  }

  push (url, options = {}) {
    this.stack.push(new RequestData(url, options))
  }
}

class RequestData {
  constructor (url, options) {
    this.url = url
    this.options = options
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.ajax-modal').forEach(element => {
  element.instance = element.instance || new AjaxModal(element)
}))
