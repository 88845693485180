
import Component from '../../../assets/scripts/modules/component'

export default class AnnotationsOverview extends Component {
  init () {
    // this.observedIntersectionElements = this.element.querySelectorAll('.article-video-embed__wrapper')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.section-annotations-overview').forEach(element => {
  element.instance = element.instance || new AnnotationsOverview(element)
}))
