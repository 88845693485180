
// import konamiCode from './utilities/konami-code.js'

// Import in reverse order from stylesheets
require('../../components/structures/ajax-modal/ajax-modal.js')
require('../../components/structures/exhibition-slideshow/exhibition-slideshow.js')
require('../../components/structures/footer-bar/footer-bar.js')
require('../../components/structures/menu-bar/menu-bar.js')
require('../../components/structures/menu-overlay/menu-overlay.js')
require('../../components/structures/mirador/mirador.js')
require('../../components/structures/project-workspace/project-workspace.js')
require('../../components/structures/project-workspace/utils.js')
require('../../components/structures/tooltip/tooltip.js')
require('../../components/organisms/article-carousel/article-carousel.js')
require('../../components/organisms/article-photo-carousel/article-photo-carousel.js')
require('../../components/organisms/article-video-embed/article-video-embed.js')
require('../../components/organisms/content-with-sidebar/content-with-sidebar.js')
require('../../components/organisms/explore-header/explore-header.js')
require('../../components/organisms/modal-stack/modal-stack.js')
require('../../components/organisms/object-carousel/object-carousel.js')
require('../../components/organisms/object-info/object-info.js')
require('../../components/organisms/object-relationships/object-relationships.js')
require('../../components/organisms/picture-header/picture-header.js')
require('../../components/organisms/project-header/project-header.js')
require('../../components/organisms/related-cards-carousel/related-cards-carousel.js')
require('../../components/organisms/search-results/search-results.js')
require('../../components/organisms/section-annotations-overview/section-annotations-overview.js')
require('../../components/organisms/simple-modal/simple-modal.js')
require('../../components/molecules/feature-card/feature-card.js')
require('../../components/molecules/filter-list/filter-list.js')
require('../../components/molecules/form/form.js')
require('../../components/molecules/member-management-form/member-management-form.js')
require('../../components/molecules/mobile-notification/mobile-notification.js')
require('../../components/molecules/object-annotation-overview/object-annotation-overview.js')
require('../../components/molecules/user-info/user-info.js')
require('../../components/atoms/button/button.js')
require('../../components/atoms/collapse/collapse.js')
require('../../components/atoms/date-histogram-field/date-histogram-field.js')
require('../../components/atoms/dropdown-field/dropdown-field.js')
require('../../components/atoms/faq/faq.js')
require('../../components/atoms/file-field/file-field.js')
require('../../components/atoms/icon-button/icon-button.js')
require('../../components/atoms/link/link.js')
require('../../components/atoms/picture/picture.js')
require('../../components/atoms/text-field/text-field.js')
require('../../components/atoms/toggle-button/toggle-button.js')
require('../../components/atoms/video-embed/video-embed.js')

console.log('\n %cMade with %c♥%c by Fabrique \n', 'font: 16px serif;', 'font: 13px serif; color: #f00;', 'font: 16px serif;')

// There are 4 load events:
// - 'init-immediate': (compiles when the script is loaded; blocks rendering)
// - 'init-load': (on DOMContentLoaded event; does not block rendering)
// - 'init-after-load': (on Load event, slightly after DOMContentLoaded)
// - 'init-delayed-load': (after Load event, with a slight delay, for iframes and such)
// Usually, the 'init-load' event will suffice.
window.dispatchEvent(new CustomEvent('init-immediate'))
window.addEventListener('DOMContentLoaded', () => window.dispatchEvent(new CustomEvent('init-load')))
window.addEventListener('load', () => window.dispatchEvent(new CustomEvent('init-after-load')))
window.addEventListener('load', () => window.setTimeout(() => window.dispatchEvent(new CustomEvent('init-delayed-load')), 500))

// Focus stuff
document.addEventListener('keydown', () => document.documentElement.classList.add('key-pressed'))
document.addEventListener('mousedown', () => document.documentElement.classList.remove('key-pressed'))
document.addEventListener('touchstart', () => document.documentElement.classList.remove('key-pressed'))

if (document.documentElement.classList.contains('is-ios') || document.documentElement.classList.contains('is-android')) {
  const onResizeHandler = () => {
    window.requestAnimationFrame(() => {
      document.documentElement.style.setProperty('--real-100vw', `${document.documentElement.clientWidth}px`)
      document.documentElement.style.setProperty('--real-100vh', `${document.documentElement.clientHeight}px`)
      document.documentElement.style.setProperty('--real-100dw', `${document.documentElement.offsetWidth}px`)
      document.documentElement.style.setProperty('--real-100dh', `${document.documentElement.offsetHeight}px`)
    })
  }
  window.addEventListener('resize', () => onResizeHandler())
  onResizeHandler()
}
