import * as actions from 'mirador/dist/es/src/state/actions'
import {
  getAllowedWindowViewTypes,
  getCanvases,
  getCanvasIndex,
  getNextCanvasGrouping,
  getPreviousCanvasGrouping,
  getWindowViewType,
  getWorkspace
} from 'mirador/dist/es/src/state/selectors'
import { render } from 'react-dom'
import { connect, Provider } from 'react-redux'

import React from 'react'
import IconArrow from '../../../../assets/icons/custom/arrow.svg'
import IconViewBook from '../../../../assets/icons/custom/view-book.svg'
import IconViewGallery from '../../../../assets/icons/custom/view-gallery.svg'
import IconViewSingle from '../../../../assets/icons/custom/view-single.svg'

const NavigationPanel = (props) => {
  if (props.isWorkspaceAddVisible || props.canvases.length < 1) return null

  const rangeSliderValue = props.canvasIndex + 1
  const displayRangerSlider = props.windowViewType === 'book' || props.windowViewType === 'single'
  const displayNav = ['book', 'single'].includes(props.windowViewType)

  const availableViewTypes = [
    {
      type: 'single',
      icon: <IconViewSingle />
    },
    {
      type: 'book',
      icon: <IconViewBook />
    },
    {
      type: 'gallery',
      icon: <IconViewGallery />
    }
  ]

  const getViewTypeButtons = availableViewTypes.map((view) => {
    const { type } = view
    if (!props.viewTypes.includes(type)) return null
    return (
      <button
        key={type}
        type="button"
        className="mirador__view-btns"
        onClick={() => props.setWindowViewType(type)}
        disabled={props.windowViewType === type}
      >
        {view.icon}
      </button>
    )
  })

  const handleOnChangeRangeSlider = (event) => {
    const { value } = event.target
    const index = parseInt(value) - 1
    props.setCanvas(props.canvases[index].id)
  }

  const handleOnClickPrevious = () => props.hasPreviousCanvas && props.setPreviousCanvas()
  const handleOnClickNext = () => props.hasNextCanvas && props.setNextCanvas()

  return (
    <>
      {displayRangerSlider && (
        <input
          type="range"
          className="mirador__range-slider"
          min="1"
          max={props.canvases.length}
          value={rangeSliderValue}
          onChange={handleOnChangeRangeSlider}
        />
      )}

      <div className="mirador__nav-btns-wrapper">
        {displayNav && (
          <>
            <button
              type="button"
              className="mirador__nav-btns mirador__nav-btns--prev"
              onClick={handleOnClickPrevious}
              disabled={!props.hasPreviousCanvas}
            >
              <IconArrow />
            </button>
            <span>
              {props.canvasIndex + 1}/{props.canvases.length}
            </span>
            <button
              type="button"
              className="mirador__nav-btns mirador__nav-btns--next"
              onClick={handleOnClickNext}
              disabled={!props.hasNextCanvas}
            >
              <IconArrow />
            </button>
          </>
        )}
      </div>

      <div className='mirador__nav-view-wrapper'>
        {getViewTypeButtons}
      </div>
    </>
  )
}

const mapStateToProps = (state, { windowId }) => {
  return {
    canvasIndex: getCanvasIndex(state, { windowId }),
    canvases: getCanvases(state, { windowId }),
    hasNextCanvas: !!getNextCanvasGrouping(state, { windowId }),
    hasPreviousCanvas: !!getPreviousCanvasGrouping(state, { windowId }),
    viewTypes: getAllowedWindowViewTypes(state, { windowId }),
    windowViewType: getWindowViewType(state, { windowId }),
    isWorkspaceAddVisible: getWorkspace(state).isWorkspaceAddVisible
  }
}

const mapDispatchToProps = (dispatch, { windowId }) => ({
  setNextCanvas: (...args) => dispatch(actions.setNextCanvas(windowId)),
  setPreviousCanvas: (...args) => dispatch(actions.setPreviousCanvas(windowId)),
  setCanvas: (...args) => dispatch(actions.setCanvas(windowId, ...args)),
  setWindowViewType: (...args) =>
    dispatch(actions.setWindowViewType(windowId, ...args))
})

const ConnectedNavigationPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationPanel)

export default (container, miradorStore, windowId) => {
  render(<Provider store={miradorStore}><ConnectedNavigationPanel windowId={windowId} /></Provider>, container)
}
