import * as actions from 'mirador/dist/es/src/state/actions'
import { getViewer } from 'mirador/dist/es/src/state/selectors'
import { useState } from 'react'
import { connect } from 'react-redux'

import React from 'react'
import IconBrightness from '../../../../assets/icons/custom/brightness.svg'
import IconClose from '../../../../assets/icons/custom/close.svg'
import IconContrast from '../../../../assets/icons/custom/contrast.svg'
import IconFlip from '../../../../assets/icons/custom/flip.svg'
import IconGreyscale from '../../../../assets/icons/custom/greyscale.svg'
import IconInvert from '../../../../assets/icons/custom/invert.svg'
import IconRevert from '../../../../assets/icons/custom/refresh.svg'
import IconRotateLeft from '../../../../assets/icons/custom/rotate-left.svg'
import IconRotateRight from '../../../../assets/icons/custom/rotate-right.svg'
import IconSaturation from '../../../../assets/icons/custom/saturation.svg'

const ImageTools = ({
  windowId,
  isOpen,
  toggleImageTools,
  viewConfig,
  updateViewport
}) => {
  const {
    rotation = 0,
    flip = false,
    brightness = 100,
    contrast = 100,
    saturate = 100,
    grayscale = 0,
    invert = 0
  } = viewConfig

  const [brightnessSliderOpen, setBrightnessSliderOpen] = useState(false)
  const [contrastSliderOpen, setContrastSliderOpen] = useState(false)
  const [saturationSliderOpen, setSaturationSliderOpen] = useState(false)
  const toggleBrightnessSlider = () => setBrightnessSliderOpen((open) => !open)
  const toggleContrastSlider = () => setContrastSliderOpen((open) => !open)
  const toggleSaturationSlider = () => setSaturationSliderOpen((open) => !open)

  const toggleRotate = (value) => {
    const offset = flip ? -1 * value : value
    updateViewport({ rotation: (rotation + offset) % 360 })
  }

  const toggleFlip = () => {
    updateViewport({ flip: !flip })
  }

  const toggleGreyscale = () => {
    const grayscaleValue = grayscale > 0 ? 0 : 100
    updateViewport({ grayscale: grayscaleValue })
  }

  const toggleInvert = () => {
    const invertValue = invert > 0 ? 0 : 100
    updateViewport({ invert: invertValue })
  }

  const handleReset = () => {
    updateViewport({
      rotation: 0,
      flip: false,
      brightness: 100,
      contrast: 100,
      saturate: 100,
      grayscale: 0,
      invert: 0
    })
  }

  const handleChange = (param) => (event) => {
    const value = parseInt(event.target.value)
    updateViewport({ [param]: value })
  }

  return (
    <div className={`mirador__image-tools ${isOpen ? 'is-open' : ''}`}>
      <div className="mirador__image-tools-container">
        <div className="mirador__image-tool">
          <button className="mirador__image-tool-btn" aria-label="Rotate left" onClick={() => toggleRotate(-90)}>
            <IconRotateLeft width="18px" />
          </button>
        </div>

        <div className="mirador__image-tool">
          <button className="mirador__image-tool-btn" aria-label="Rotate right" onClick={() => toggleRotate(90)}>
            <IconRotateRight width="18px" />
          </button>
        </div>

        <div className={`mirador__image-tool ${flip ? 'is-active' : ''}`}>
          <button className="mirador__image-tool-btn" aria-label="Flip" onClick={toggleFlip}>
            <IconFlip width="20px" />
          </button>
        </div>

        <div
          className={`mirador__image-tool ${brightnessSliderOpen ? 'is-active' : ''
            }`}
        >
          <button className="mirador__image-tool-btn" aria-label="Brightness" onClick={toggleBrightnessSlider}>
            <IconBrightness width="18px" />
          </button>
          {brightnessSliderOpen && (
            <div className="mirador__image-tool-slider">
              <input
                type="range"
                min="0"
                max="200"
                value={brightness}
                onChange={handleChange('brightness')}
              />
            </div>
          )}
        </div>

        <div
          className={`mirador__image-tool ${contrastSliderOpen ? 'is-active' : ''
            }`}
        >
          <button className="mirador__image-tool-btn" aria-label="Contrast" onClick={toggleContrastSlider}>
            <IconContrast width="13px" />
          </button>
          {contrastSliderOpen && (
            <div className="mirador__image-tool-slider">
              <input
                type="range"
                min="0"
                max="200"
                value={contrast}
                onChange={handleChange('contrast')}
              />
            </div>
          )}
        </div>

        <div
          className={`mirador__image-tool ${saturationSliderOpen ? 'is-active' : ''
            }`}
        >
          <button className="mirador__image-tool-btn" aria-label="Saturation" onClick={toggleSaturationSlider}>
            <IconSaturation width="13px" />
          </button>
          {saturationSliderOpen && (
            <div className="mirador__image-tool-slider">
              <input
                type="range"
                min="0"
                max="200"
                value={saturate}
                onChange={handleChange('saturate')}
              />
            </div>
          )}
        </div>

        <div
          className={`mirador__image-tool ${grayscale > 0 ? 'is-active' : ''}`}
        >
          <button className="mirador__image-tool-btn" aria-label="Greyscale" onClick={toggleGreyscale}>
            <IconGreyscale width="13px" />
          </button>
        </div>

        <div className={`mirador__image-tool ${invert > 0 ? 'is-active' : ''}`}>
          <button className="mirador__image-tool-btn" aria-label="Invert" onClick={toggleInvert}>
            <IconInvert width="12px" />
          </button>
        </div>

        <div className="mirador__image-tool">
          <button className="mirador__image-tool-btn" aria-label="Revert" onClick={handleReset}>
            <IconRevert width="13px" />
          </button>
        </div>

        <div className="mirador__image-tool">
          <button className="mirador__image-tool-btn" aria-label="Close image tools" onClick={toggleImageTools}>
            <IconClose width="16px" />
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, { windowId }) => ({
  viewConfig: getViewer(state, { windowId }) || {}
})

const mapDispatchToProps = (dispatch, { windowId }) => ({
  updateViewport: (viewConfig) => dispatch(actions.updateViewport(windowId, viewConfig))
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageTools)
