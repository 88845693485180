import React, { useEffect, useState } from 'react'

export default function Tooltip ({ classes = '', position = 'right', title = '', children }) {
  const [dispatched, setDispatched] = useState(false)
  // Fires event so old tooltip from design system can do it's work, right after the

  useEffect(() => {
    const timeoutF = setTimeout(() => {
      window.dispatchEvent(new Event('react-tooltip-load'))
      setDispatched(true)
    }, 5)

    if (dispatched) clearTimeout(timeoutF)
  }, [title, position])

  return (
    <div className={`${classes} tooltip--${position}`} data-tooltip={title}>
      {children}
    </div>
  )
}
