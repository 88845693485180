
import Component from '../../../assets/scripts/modules/component'

let timeoutId = null
export default class UserInfo extends Component {
  init () {
    this.open = false
    this.button = this.element.querySelector('.user-info__button')
    this.menu = this.element.querySelector('.user-info__menu')

    if (!this.button || !this.menu) return

    this.initExpandSupport()
  }

  initExpandSupport () {
    const toggleMenu = (bool) => {
      this.menu.classList.toggle('floating-menu--active', bool)
      this.element.classList.toggle('user-info--open', bool)
      this.open = bool
    }

    const handleMouseOver = () => {
      clearTimeout(timeoutId)
      toggleMenu(true)
    }

    const handleMouseOut = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => toggleMenu(false), 100)
    }

    this.button.addEventListener('mouseover', handleMouseOver)
    this.button.addEventListener('mouseout', handleMouseOut)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.user-info').forEach(element => {
  element.instance = element.instance || new UserInfo(element)
}))
