import { wrapGrid } from 'animate-css-grid'

import Component from '../../../assets/scripts/modules/component'
import { hydrate } from '../../../assets/scripts/utilities/hydrator'

const AJAX_OPTIONS = {
  mode: 'same-origin',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
}

// function postProcessFormData (form, formData) {
//   const inputFields = Array.from(form.querySelectorAll('input'))
// }

export default class SearchResults extends Component {
  init () {
    this.initSidebar()
    this.initInfiniteScrollSupport()
    this.initSearchAutoRefreshSupport()

    // Glitches when not helping
    // this.initAnimatedGrid()
  }

  initAnimatedGrid () {
    wrapGrid(
      this.element.querySelector('.search-results__grid-container'),
      { duration: 300, easing: 'circOut' }
    )
  }

  initSidebar () {
    const sidebarCloser = this.element.querySelector('.search-results__sidebar-close')
    const gridContainer = this.element.querySelector('.search-results__grid-container')
    let isOpen = true

    function closeSidebar () {
      isOpen = false
      this.element.classList.add('search-results--sidebar-closing')
      this.element.classList.remove('search-results--open-sidebar')
      gridContainer.classList.toggle('grid-effect')

      setTimeout(() => {
        this.element.classList.remove('search-results--sidebar-closing')
      }, 300)
    }

    function openSidebar () {
      isOpen = true
      this.element.classList.add('search-results--sidebar-opening')
      this.element.classList.add('search-results--open-sidebar')
      gridContainer.classList.toggle('grid-effect')

      setTimeout(() => {
        this.element.classList.remove('search-results--sidebar-opening')
      }, 300)
    }

    sidebarCloser.addEventListener('click', closeSidebar.bind(this))

    Array.from(document.querySelectorAll('[data-toggle-sidebar]')).forEach((element) => {
      element.addEventListener('click', () => {
        (isOpen ? closeSidebar : openSidebar).bind(this)()
      })
    })

    // (isOpen ? openSidebar : closeSidebar).bind(this)()
  }

  initInfiniteScrollSupport () {
    let requestPending = false
    let itemsRemaining = true
    let page = Math.min(8, Number(new URLSearchParams(window.location.search).get('pages') || 1))
    const itemContainer = this.element.querySelector('.search-results__grid-container')
    const form = this.element.querySelector('form')

    async function requestNewItems () {
      const formData = new FormData(form)
      page += 1

      // Push a new url, so refreshing will load the same set of pages.
      // We don't want to load too many items, so we do this up to a maximum
      if (page <= 8) {
        const searchParamsForState = new URLSearchParams(formData)
        searchParamsForState.append('pages', '' + page)
        window.history.pushState({}, '', `?${searchParamsForState.toString()}`)
      }

      formData.append('page', '' + page)
      // TODO: get this url from somewhere -- not hard coded
      const response = await fetch(`/ajax/search/?${new URLSearchParams(formData).toString()}`, AJAX_OPTIONS)
      if (response.status !== 200) {
        return []
      }

      const data = await response.json()
      itemsRemaining = data.items_remaining
      return data.items
    }

    async function addNewItems () {
      requestPending = true
      const items = await requestNewItems()
      const parser = new DOMParser()
      for (const item of items) {
        const html = parser.parseFromString(item, 'text/html')
        itemContainer.appendChild(html.body.firstChild)
      }
      // itemContainer.innerHTML += items.join('')
      hydrate(itemContainer)
      requestPending = false
    }

    window.addEventListener('scroll', async () => {
      if (requestPending || !itemsRemaining) {
        return
      }

      if (document.body.scrollHeight - window.innerHeight * 2 - window.scrollY < 0) {
        return addNewItems()
      }
    })
  }

  // Add support to auto refresh the search when the form changes.
  initSearchAutoRefreshSupport () {
    const form = this.element.querySelector('form')
    const inputs = form ? Array.from(form.querySelectorAll('input')) : []
    let refreshTimeout = null
    inputs.forEach((input) => {
      input.addEventListener('change', () => {
        form.submit()
      })
      input.addEventListener('keydown', () => {
        if (refreshTimeout) {
          clearTimeout(refreshTimeout)
        }
        refreshTimeout = setTimeout(() => form.submit(), 2500)
      })
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.search-results').forEach(element => {
  element.instance = element.instance || new SearchResults(element)
}))
