import React from 'react'
import { SquareLoader } from 'react-spinners'

const colors = {
  'pink-light': '#ffcccb',
  pink: '#e88f8f',
  'pink-dark': '#bd5b5d'
}

export default function JCBLoader ({ color = 'pink-light', message = '', size = 24 }) {
  return (
    <div className="JCBLoader">
      <SquareLoader color={colors[color]} size={size} />
      <p className="h7">{message}</p>
    </div>
  )
}
