import MicroModal from '../../../assets/scripts/plugins/micro-modal'

class SimpleModal {
  constructor (element) {
    MicroModal.init({})
    MicroModal.show(element.id, {})
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.simple-modal').forEach(element => {
  element.instance = element.instance || new SimpleModal(element)
}))
