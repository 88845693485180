
const initExhibitionSlideshow = async (selector) => {
  const exhibitionSlideshowContainer = document.getElementById(selector)

  if (!exhibitionSlideshowContainer) {
    return
  }

  // Dynamically import the ExhibitionSlideshow page
  const ExhibitionSlideshow = (await import('./components/ExhibitionSlideshow')).default

  const { exhibitionId, variant } = exhibitionSlideshowContainer.dataset

  ExhibitionSlideshow(exhibitionSlideshowContainer, exhibitionId, variant)
}

window.addEventListener('init-load', async () => {
  await initExhibitionSlideshow('js-exhibition-slideshow-container')
})
