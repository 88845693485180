import Component from '../../../assets/scripts/modules/component'

class ObjectInfoComponent extends Component {
  init () {
    this.objectFrame = this.element.querySelector('.object-info__image')

    this.image = this.objectFrame.querySelector('.picture__image')
    this.pencilSvg = this.element.querySelector('.object-info__scale-pencil')

    if (!(this.objectFrame && this.image && this.pencilSvg)) return

    this.image.addEventListener('load', () => this.positionPencil())
    window.addEventListener('resize', () => this.positionPencil())

    this.positionPencil()
  }

  positionPencil () {
    const imageWidth = this.image.clientWidth
    const frameWidth = this.objectFrame.clientWidth

    this.pencilSvg.style.position = 'relative'
    this.pencilSvg.style.left = `${Math.floor(imageWidth - frameWidth + 8)}px`
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.object-info').forEach(element => {
  element.instance = element.instance || new ObjectInfoComponent(element)
}))
