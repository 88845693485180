import DOMPurify from 'dompurify'
import { useState } from 'react'
import { connect } from 'react-redux'

import React from 'react'
import IconOptions from '../../../../../assets/icons/custom/options.svg'
import IconButton from '../../../../atoms/icon-button/react/IconButton'

const mapStateToProps = (state, { annotation, editAnnotation, deleteAnnotation, hoverAnnotation, blurAnnotation }) => {
  return {
    annotation: annotation,
    editAnnotation: editAnnotation,
    deleteAnnotation: deleteAnnotation,
    hoverAnnotation: hoverAnnotation,
    blurAnnotation: blurAnnotation
  }
}

const AnnotationItem = ({
  annotation,
  editAnnotation,
  deleteAnnotation,
  hoverAnnotation,
  blurAnnotation
}) => {
  const showEditActions = () => {
    setEditOptionsVisible(!editOptionsVisible)
  }

  const [editOptionsVisible, setEditOptionsVisible] = useState(false)

  const editButtons = () => {
    return (
      <span>
        <IconButton
          icon='trash'
          size='small'
          title='Delete annotation'
          onClick={() => deleteAnnotation(annotation.id)}
        />
        <IconButton
          icon='pen'
          size='small'
          title='Edit annotation'
          onClick={() => editAnnotation('annotationCreation', { annotationid: annotation.id, position: 'right' })}
        />
      </span>
    )
  }

  return (
    <li key={annotation.id}
      onMouseEnter={() => hoverAnnotation(annotation.id)}
      onMouseLeave={blurAnnotation}>
      <div className="mirador__annotations-panel__annotation-actions">
        {editOptionsVisible ? editButtons() : ''}
        <button
          className='button--options'
          type="button"
          onClick={() => showEditActions()}
        >
          <span className="button__icon" ><IconOptions /></span>
        </button>
      </div>
      <div
        className="mirador__annotations-panel__annotation-content"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(annotation.content)
        }}
      />
    </li>
  )
}

export default connect(mapStateToProps)(AnnotationItem)
