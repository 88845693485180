import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

function findZIndex (element) {
  if (!element) {
    return 0
  }

  const zIndex = getComputedStyle(element).zIndex
  if (zIndex === 'auto') {
    return findZIndex(element.parentElement)
  }
  return findZIndex(element.parentElement) || zIndex
}

export default class ContentWithSidebar extends Component {
  init () {
    this.sidebar = this.element.querySelector('.content-with-sidebar-sidebar')
    this.sidebar.style.zIndex = findZIndex(this.element) + 1
    this.sidebar.remove()
    document.body.appendChild(this.sidebar)

    this.listeners = this.observe()
  }

  observe () {
    const listeners = []

    const listener = event => {
      if (event.target.closest('.content-with-sidebar__toggle-button')) {
        this.toggleSidebar()
      }

      if (event.target.closest('.content-with-sidebar__open-button')) {
        this.openSidebar()
      }

      if (event.target.closest('.content-with-sidebar__close-button')) {
        this.closeSidebar()
      }
    }

    document.body.addEventListener('click', listener)
    listeners.push({ element: document.body, event: 'click', listener })

    const observer = new MutationObserver(() => {
      if (!document.contains(this.element)) {
        observer.disconnect()
        this.sidebar.remove()
        this.unobserve()
      }
    })

    observer.observe(document, {
      childList: true,
      subtree: true
    })

    return listeners
  }

  unobserve () {
    if (!this.listeners) {
      return
    }

    for (const { element, event, listener } of this.listeners) {
      element.removeEventListener(event, listener)
    }
  }

  openSidebar () {
    this.element.classList.add('content-with-sidebar--sidebar-open')
    this.sidebar.classList.add('content-with-sidebar-sidebar--open')
  }

  closeSidebar () {
    this.element.classList.remove('content-with-sidebar--sidebar-open')
    this.sidebar.classList.remove('content-with-sidebar-sidebar--open')
  }

  toggleSidebar () {
    this.element.classList.toggle('content-with-sidebar--sidebar-open')
    this.sidebar.classList.toggle('content-with-sidebar-sidebar--open')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.content-with-sidebar').forEach(element => {
  element.instance = element.instance || new ContentWithSidebar(element)
}))

hydratorRegisterComponent('.content-with-sidebar', ContentWithSidebar)
