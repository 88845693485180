import initMiradorAnnotationViewer from '../../../assets/scripts/modules/init-mirador-annotation-viewer'

import Component from '../../../assets/scripts/modules/component'

export default class MiradorComponent extends Component {
  init () {
    this.loadMirador()
  }

  async loadMirador () {
    initMiradorAnnotationViewer('js-mirador-viewer')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.js-mirador-container').forEach(element => {
  element.instance = element.instance || new MiradorComponent(element)
}))
