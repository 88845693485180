import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterCallable } from '../../../assets/scripts/utilities/hydrator'

const TOOLTIP_WIDTH = 290
const TOOLTIP_EXTRA_SPACE = 20
const DELAY = 400

export default class Tooltip extends Component {
  init () {
    this.delayTimeout = null
    this.toggleActive(false)

    this.initFollowMouseSupport()
    this.initTargets()

    // temporary react solution until there is time to build a new React component for Tooltip
    window.addEventListener('react-tooltip-load', () => {
      this.delayTimeout = null
      this.toggleActive(false)

      this.initFollowMouseSupport()
      this.initTargets()
    })
  }

  // Show or hide the tooltip with a time delay.
  toggleActiveDelayed (newValue) {
    clearTimeout(this.delayTimeout)
    this.delayTimeout = setTimeout(() => {
      this.toggleActive(newValue)
    }, DELAY)
  }

  // Show or hide the tooltip
  toggleActive (newValue) {
    clearTimeout(this.delayTimeout)
    this.tooltipActive = newValue
    this.element.classList[this.tooltipActive ? 'add' : 'remove']('tooltip--active')
  }

  // Setup support for positioning the tooltip according to the mouse position
  initFollowMouseSupport () {
    function getVariant (positionX) {
      // eslint-disable-next-line smells/no-complex-string-concat
      if (positionX + (TOOLTIP_WIDTH + TOOLTIP_EXTRA_SPACE) > window.innerWidth) {
        return 'left'
      }

      if (positionX - (TOOLTIP_WIDTH + TOOLTIP_EXTRA_SPACE) < 0) {
        return 'right'
      }

      return 'right'
    }

    let variant = 'right'

    const mouseOverHander = (event) => {
      this.element.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`

      const newVariant = getVariant(event.clientX)

      if (newVariant && newVariant !== variant) {
        this.element.classList.remove(`tooltip--${variant}`)
        this.element.classList.add(`tooltip--${newVariant}`)
        variant = newVariant
      }
    }

    window.removeEventListener('mousemove', mouseOverHander)
    window.addEventListener('mousemove', mouseOverHander)
  }

  // Setup support for showing a tooltip when hovering over an item with a tooltip
  initTargets () {
    function setupTargets (container) {
      const targets = Array.from(container.querySelectorAll('[data-tooltip]'))

      targets.forEach((target) => {
        // Don't display empty tooltips
        if (!target.getAttribute('data-tooltip').length) {
          console.log(target.getAttribute('data-tooltip'))
          return
        }

        if (!target.tooltipInitialized) {
          target.tooltipInitialized = true

          const mouseOverHandler = () => {
            // Tooltips are stored url encoded, so they don't break any HTML
            textContainer.innerHTML = decodeURIComponent(target.getAttribute('data-tooltip'))
            this.toggleActiveDelayed(true)
          }

          const mouseOutHandler = () => {
            this.toggleActive(false)
          }

          target.removeEventListener('mouseover', mouseOverHandler)
          target.removeEventListener('mouseout', mouseOutHandler)

          target.addEventListener('mouseover', mouseOverHandler)
          target.addEventListener('mouseout', mouseOutHandler)
        }
      })
    }

    const textContainer = this.element.querySelector('.tooltip__text')

    // Add Ajax loaded content support
    hydratorRegisterCallable(setupTargets.bind(this))
    setupTargets.bind(this)(document)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.tooltip').forEach(element => {
  element.instance = element.instance || new Tooltip(element)
}))
