import React, { useRef } from 'react'

const Button = React.forwardRef(({ classes = '', variant = 'secondary', title, iconBefore, iconAfter, loading, ...buttonProps }, ref) => {
  const getIcon = (icon) => {
    const Icon = require(`../../../../assets/icons/custom/${icon}.svg`).default

    return <Icon />
  }

  return (
    <button ref={ref} className={`button button--${variant} ${loading ? 'button--loading' : ''} ${classes}`} {...buttonProps}>
      {iconBefore && <span className="button__icon button__icon--before">{getIcon(iconBefore)}</span>}
      <span className="button__span">{title}</span>
      {iconAfter && <span className="button__icon button__icon--after">{getIcon(iconAfter)}</span>}
    </button>
  )
})

Button.displayName = 'Button'

const ButtonLink = React.forwardRef(({ classes = '', variant = 'secondary', title, iconBefore, iconAfter, ...linkProps }, ref) => {
  const getIcon = (icon) => {
    const Icon = require(`../../../../assets/icons/custom/${icon}.svg`).default

    return <Icon />
  }

  return (
    <a ref={ref} className={`button button--${variant} ${classes}`} {...linkProps}>
      {iconBefore && <span className="button__icon button__icon--before">{getIcon(iconBefore)}</span>}
      <span className="button__span">{title}</span>
      {iconAfter && <span className="button__icon button__icon--after">{getIcon(iconAfter)}</span>}
    </a>
  )
})

ButtonLink.displayName = 'ButtonLink'

const FileButton = ({ classes, onChange, accept, ...buttonProps }) => {
  const ref = useRef()

  const onClick = () => {
    if (!ref.current) return

    ref.current.click()
  }

  return (
    <div className={`file-button ${classes}`}>
      <input ref={ref} className='file-button__input' type='file' accept={accept} onChange={onChange} />
      <Button {...buttonProps} onClick={onClick} />
    </div>
  )
}

const TabButton = ({ title = '', active = false, classes = '', ...buttonProps }) => {
  return <button className={`TabButton ${active ? 'TabButton--active' : ''} ${classes}`} {...buttonProps}>{title}</button>
}

export { Button, ButtonLink, FileButton, TabButton }
