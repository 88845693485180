
import Component from '../../../assets/scripts/modules/component'

export default class Faq extends Component {
  init () {
    this.initExpandSupport()
    this.initOnResize()
  }

  initExpandSupport () {
    const expandButton = this.element.querySelector('.faq__question')
    const answerContainer = this.element.querySelector('.faq__answer')
    const answerRichtext = answerContainer?.querySelector('.richtext')

    if (!answerContainer || !answerRichtext) {
      return
    }

    // const id = this.element.id
    const isExpandable = true
    // let isExpanded = // window.location.hash.substr(1) === id
    let isExpanded = this.element.getAttribute('data-expanded') === 'true'

    function expand () {
      this.element.classList.add('faq--open')
      this.element.setAttribute('data-expanded', 'true')

      answerContainer.style.maxHeight = `${answerRichtext.offsetHeight}px`

      // const rect = answerContainer.getBoundingClientRect()
      // window.scroll({
      //  top: (window.scrollY + rect.top) - (window.innerHeight / 3),
      //  left: 0,
      //  behavior: 'smooth'
      // })
    }

    function collapse () {
      this.element.classList.remove('faq--open')
      this.element.setAttribute('data-expanded', 'false')
      answerContainer.style.maxHeight = '0'
    }

    function apply () {
      (isExpanded ? expand.bind(this) : collapse.bind(this))()
    }

    if (isExpandable) {
      expandButton.addEventListener('click', () => {
        isExpanded = !isExpanded
        apply.bind(this)()
      })
      answerContainer.addEventListener('click', () => {
        isExpanded = !isExpanded
        apply.bind(this)()
      })

      apply.bind(this)()
    }
  }

  initOnResize () {
    window.addEventListener('resize', () => this.onResizeHandler())
    this.onResizeHandler()
  }

  // This functions is called on resize and on init
  // It is used to set the max-height of the answer container
  onResizeHandler () {
    const answerContainer = this.element.querySelector('.faq__answer')
    const answerRichtext = answerContainer?.querySelector('.richtext')

    if (!answerContainer || !answerRichtext) {
      return
    }

    answerContainer.style.maxHeight = this.element.getAttribute('data-expanded') === 'true' ? `${answerRichtext.offsetHeight}px` : '0'
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.faq').forEach(element => {
  element.instance = element.instance || new Faq(element)
}))
