import Component from '../../../assets/scripts/modules/component'

class ProjectHeaderComponent extends Component {
  init () {
    this.optionToggle = this.element.querySelector('.js-project-detail-options-toggle-btn')
    this.optionBtns = this.element.querySelector('.js-project-detail-options-btns')

    if (!this.optionToggle || !this.optionBtns) {
      return
    }

    this.optionToggle.addEventListener('click', () => {
      this.showButtons()
    })
  }

  showButtons () {
    // TODO fix this line of code
    this.optionBtns.classList.toggle('is-open')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.js-project-header').forEach(element => {
  element.instance = element.instance || new ProjectHeaderComponent(element)
}))
