
import Component from '../../../assets/scripts/modules/component'

export default class MenuBarComponent extends Component {
  init () {
    this.buttonMenu = this.element.querySelector('.button--menu')

    if (!this.buttonMenu) {
      return
    }

    this.buttonMenu.addEventListener('click', () => window.dispatchEvent(new CustomEvent('open-menu-overlay')))
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.menu-bar').forEach(element => {
  element.instance = element.instance || new MenuBarComponent(element)
}))
