
const initProjectWorkspace = async (selector) => {
  const projectWorkspaceContainer = document.getElementById(selector)

  if (!projectWorkspaceContainer) {
    return
  }

  // Dynamically import the ProjectWorkspace app
  const ProjectWorkspace = (await import('./components/ProjectWorkspace')).default

  const { projectId } = projectWorkspaceContainer.dataset

  ProjectWorkspace(projectWorkspaceContainer, projectId, {
    checkboxLabel: projectWorkspaceContainer.querySelector('[data-role="submit-exhibition-checkbox-label"]').innerHTML
  })
}

window.addEventListener('init-load', async () => {
  await initProjectWorkspace('js-project-workspace-container')
})
