
const FETCH_HEADERS = {
  mode: 'same-origin'
}

const fireEvent = (type) => {
  const event = new Event(type)
  document.dispatchEvent(event)
}

const fireCustomEvent = (type, detail) => {
  const event = new CustomEvent(type, { detail })
  document.dispatchEvent(event)
}

// Annotations adapter for JCBL
export default class JCBLAdapter {
  constructor (annotationPageId) {
    this.annotationPageId = annotationPageId
  }

  async create (annotation) {
    fireEvent('annotations-create-loading')

    const formData = new FormData()
    formData.append('canvas', this.annotationPageId)
    formData.append('data', JSON.stringify(annotation))
    formData.append('project_object_id', window.JCBL_OBJECT_CONTEXT.project_object_id)
    formData.append('csrftoken', window.csrftoken)

    let result = null

    try {
      const response = await fetch(
        new Request(
          '/annotation/create/',
          { headers: { 'X-CSRFToken': window.csrftoken } }
        ),
        {
          method: 'POST',
          body: formData,
          ...FETCH_HEADERS
        })

      if (response.ok) {
        result = await response.json()
        fireEvent('annotations-create-done')
      } else {
        fireCustomEvent('annotations-create-error', { message: 'Something went wrong while creating your annotation' })
      }
    } catch (e) {
      fireCustomEvent('annotations-create-error', e)
    }

    return result || this.all()
  }

  async update (annotation) {
    fireEvent('annotations-update-loading')

    const formData = new FormData()
    formData.append('canvas', this.annotationPageId)
    formData.append('data', JSON.stringify(annotation))
    formData.append('project_object_id', window.JCBL_OBJECT_CONTEXT.project_object_id)
    formData.append('csrftoken', window.csrftoken)

    let result = null

    try {
      const response = await fetch(
        new Request(
          `/annotation/update/${annotation.id}/`,
          { headers: { 'X-CSRFToken': window.csrftoken } }
        ),
        {
          method: 'POST',
          body: formData,
          ...FETCH_HEADERS
        })

      if (response.ok) {
        result = await response.json()
        fireEvent('annotations-update-done')
      } else {
        fireCustomEvent('annotations-update-error', { message: 'Something went wrong while updating your annotation' })
      }
    } catch (e) {
      fireCustomEvent('annotations-update-error', e)
    }

    return result || this.all()
  }

  async delete (annoId) {
    fireEvent('annotations-delete-loading')

    let result = null

    try {
      const formData = new FormData()
      formData.append('csrftoken', window.csrftoken)
      const response = await fetch(
        new Request(
          `/annotation/delete/${annoId}/`,
          { headers: { 'X-CSRFToken': window.csrftoken } }
        ),
        {
          method: 'POST',
          body: formData,
          ...FETCH_HEADERS
        })
      if (response.ok) {
        result = await response.json()
        fireEvent('annotations-delete-done')
      } else {
        fireCustomEvent('annotations-delete-error', { message: 'Something went wrong while deleting your annotation' })
      }
    } catch (e) {
      fireCustomEvent('annotations-delete-error', e)
    }

    return result || this.all()
  }

  async get (annoId) {
    const annotationPage = await this.all()
    if (annotationPage) {
      return annotationPage.items.find((item) => item.id === annoId)
    }
    return null
  }

  async all () {
    fireEvent('annotations-all-loading')

    const params = new URLSearchParams()
    params.set('canvas_identifier', this.annotationPageId)
    if (window?.JCBL_OBJECT_CONTEXT?.project_object_id) {
      params.set('project_object_id', window.JCBL_OBJECT_CONTEXT.project_object_id)
    }

    try {
      const response = await fetch(new Request(
        `/annotation/get/?${params.toString()}`,
        { headers: { 'X-CSRFToken': window.csrftoken } }
      ))

      const json = await response.json()

      if (response.status === 200) {
        fireEvent('annotations-all-done')
        return json
      }

      fireCustomEvent('annotations-all-error', json)
    } catch (e) {
      fireCustomEvent('annotations-all-error', e)
    }

    return null
  }
}
