
const initToggleBtn = toggleButtons => {
  toggleButtons.forEach(toggleBtn => {
    toggleBtn.addEventListener('click', () => {
      toggleBtn.classList.toggle('toggle-button--active')
    })
  })
}

window.addEventListener('DOMContentLoaded', () => {
  initToggleBtn(document.querySelectorAll('.js-toggle-button'))
})
