import { useEffect, useState } from 'react'

import useSyncedRef from './useSyncedRef'

// Hook that detects if an element is being hovered. Attach ref to element to watch
export default function useHover (syncedRef) {
  const [value, setValue] = useState(false)

  const ref = useSyncedRef(syncedRef) // If another ref is provided to the same element of which we're tracking hover state, sync both refs

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(() => {
    const node = ref.current
    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)

      // Also include focus events
      node.addEventListener('focusin', handleMouseOver)
      node.addEventListener('focusout', handleMouseOut)

      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
        node.removeEventListener('focusin', handleMouseOver)
        node.removeEventListener('focusout', handleMouseOut)
      }
    }
  }, [ref]) // Recall only if ref changes

  return [ref, value]
}
