import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

export default class TextField extends Component {
  init () {
    this.initAnimationSupport()

    if (this.element.classList.contains('text-field--password')) {
      this.initPasswordSupport()
    }
  }

  initPasswordSupport () {
    const input = this.element.querySelector('input')
    const showBtn = this.element.querySelector('[data-role="show-password"]')
    const hideBtn = this.element.querySelector('[data-role="hide-password"]')

    if (!showBtn || !hideBtn) return

    showBtn.addEventListener('click', (e) => {
      e.preventDefault()
      input.type = 'text'
      showBtn.hidden = true
      hideBtn.hidden = false
    })

    hideBtn.addEventListener('click', (e) => {
      e.preventDefault()
      input.type = 'password'
      showBtn.hidden = false
      hideBtn.hidden = true
    })
  }

  initAnimationSupport () {
    const input = this.element.querySelector('input')

    if (input.labels?.length && input.placeholder) {
      input.placeholder = ''
    }

    function update () {
      this.element.classList.toggle('text-field--open', !!input.value)
    }

    update.bind(this) // Fire once for prefilled fields

    input.addEventListener('input', update.bind(this))
  }
}

const registerTextFields = (container) => {
  container.querySelectorAll('.text-field').forEach(element => {
    element.instance = element.instance || new TextField(element)
  })
}

window.addEventListener('init-load', () => registerTextFields(document))
hydratorRegisterComponent('.text-field', TextField)
