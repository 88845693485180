import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import { default as Skel } from 'react-loading-skeleton'

// Small wrapper component because react-loading-skeleton requires you to import the css in file, this way we don't have to import the css in every file we use skeleton.
// Unfortunately adding css to main.scss does not work..
export default function Skeleton (skeletonProps) {
  return <Skel {...skeletonProps} />
}
