import * as actions from 'mirador/dist/es/src/state/actions'
import { getWindows, getWindowViewType, getWorkspace } from 'mirador/dist/es/src/state/selectors'
import { useEffect, useRef, useState } from 'react'
import { render } from 'react-dom'
import { connect, Provider, useStore } from 'react-redux'

import React from 'react'
import IconButton from '../../../atoms/icon-button/react/IconButton'
import IconButtonLink from '../../../atoms/icon-button/react/IconButtonLink'
import Modal from '../../ajax-modal/react/Modal'
import useModal from '../../ajax-modal/react/useModal'
import Tooltip from '../../tooltip/react/Tooltip'
import AnnotationPanel from './AnnotationPanel'
import ImageTools from './ImageTools'
import { isOpen } from '@blueprintjs/core/lib/esnext/components/context-menu/contextMenu'

const MenuItems = ({ windowId, windowViewType, toggleAnnotationDisplay, isWorkspaceAddVisible, windowsIds, initialState }) => {
  const [firstTime, setFirstTime] = useState(true)
  const [annotationsPanelOpen, setAnnotationsPanelOpen] = useState(false)
  const [imageToolsOpen, setImageToolsOpen] = useState(false)
  const { isOpen: notificationsModalIsOpen, toggleModal: toggleNotificationsModal } = useModal(false)
  const { isOpen: downloadModalIsOpen, toggleModal: toggleDownloadModal } = useModal(false)
  const { downloadTitle, downloadUrl } = initialState
  const [isPreparingDownload, setPreparingDownload] = useState(false)
  const [downloadCompleted, setDownloadCompleted] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState('')
  const [showManualDownloadUrl, setShowManualDownloadUrl] = useState(false)
  const moreButtonsRef = useRef(null)
  const downloadExtension = downloadUrl ? downloadUrl.split('.').pop() : 'jpg'
  const downloadFileName = downloadTitle ? `${downloadTitle.substring(0, 255)}.${downloadExtension}` : ''

  const handleChange = () => {
    const state = store.getState()
    if (getWindowViewType(state, { windowId }) !== 'single') {
      closeSidePanel()
    }
  }

  const store = useStore()
  store.subscribe(handleChange)

  const toggleAnnotationsPanel = () => {
    if (annotationsPanelOpen) {
      setImageToolsOpen(false)
      setAnnotationsPanelOpen((open) => !open)
    } else {
      if (windowViewType === 'single') {
        setImageToolsOpen(false)
        setAnnotationsPanelOpen((open) => !open)
      } else {
        toggleNotificationsModal()
      }
    }
  }

  const closeSidePanel = () => {
    if (annotationsPanelOpen) {
      setAnnotationsPanelOpen(false)
    }
  }

  const openRelations = () => {
    const objectPk = window.JCBL_OBJECT_CONTEXT.object_id
    const event = new CustomEvent('modal-stack:open', {
      detail: {
        modalStackId: 'relations-modal-stack',
        fetchUrl: `/api/relations/${objectPk}/overview/`
      }
    })
    window.dispatchEvent(event)
  }

  const toggleImageTools = () => setImageToolsOpen((open) => !open)

  const toggleMoreButtons = () => {
    if (!moreButtonsRef.current) return

    const isOpen = moreButtonsRef.current.classList.contains('is-open')
    moreButtonsRef.current.classList.toggle('is-open', !isOpen)
  }

  const prepareDownload = async () => {
    if (isPreparingDownload) return
    setPreparingDownload(true)
    setDownloadCompleted(false)
    toggleDownloadModal()
    let response

    try {
      response = await fetch(downloadUrl)
    } catch (error) {
      // Could be cors error
      setPreparingDownload(false)
      setShowManualDownloadUrl(true)
      return
    }
    const reader = response.body.getReader()

    // Step 2: get total length
    const contentLength = +response.headers.get('Content-Length')
    let amountToDownload = '' + contentLength

    if (contentLength) {
      if (amountToDownload > 1000000) {
        amountToDownload = `${Math.round(contentLength / 100000) / 10}mb`
      } else if (contentLength > 1000) {
        amountToDownload = `${Math.round(contentLength / 100) / 10}kb`
      }
    } else {
      amountToDownload = 'unknown length'
    }

    // Step 3: read the data
    let receivedLength = 0 // received that many bytes at the moment
    const chunks = [] // array of received binary chunks (comprises the body)
    while (true) {
      // If the modal is closed, stop downloading
      if (!isOpen) {
        setPreparingDownload(false)
        return
      }

      const { done, value } = await reader.read()

      if (done) {
        break
      }

      chunks.push(value)
      receivedLength += value.length

      let amount = '' + receivedLength
      if (receivedLength > 1000000) {
        amount = `${Math.round(receivedLength / 1000000)}mb`
      } else if (receivedLength > 1000) {
        amount = `${Math.round(receivedLength / 1000)}kb`
      }
      if (amountToDownload === 'unknown length') {
        setDownloadProgress(`${amount} downloaded`)
      } else {
        setDownloadProgress(`${amount} downloaded of ${amountToDownload}`)
      }
    }
    // Step 4: concatenate chunks into single Uint8Array
    const chunksAll = new Uint8Array(receivedLength) // (4.1)
    let position = 0
    for (const chunk of chunks) {
      chunksAll.set(chunk, position) // (4.2)
      position += chunk.length
    }
    const blob = new Blob([chunksAll], { type: response.headers.get('Content-Type') })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', downloadFileName)

    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    setPreparingDownload(false)
    setDownloadCompleted(true)
  }

  useEffect(() => {
    const miradorContainer = document.querySelector('.js-mirador-container')
    if (imageToolsOpen) {
      miradorContainer.classList.add('image-tools-is-open', 'dark-mode')
    } else if (annotationsPanelOpen) {
      miradorContainer.classList.add('annotation-panel-is-open')
      miradorContainer.classList.remove('dark-mode')
    } else {
      miradorContainer.classList.remove('annotation-panel-is-open', 'image-tools-is-open', 'dark-mode')
    }
  }, [annotationsPanelOpen, imageToolsOpen])

  useEffect(() => {
    if (!firstTime) {
      toggleAnnotationDisplay(windowId)
    }
  }, [annotationsPanelOpen])

  useEffect(() => {
    setFirstTime(false)
  }, [firstTime])

  const downloadLink = (<a href={downloadUrl} rel="noopener noreferrer" target="_blank">download the file manually</a>)

  return (
    <>
      <div className="mirador__menu-btn-group">
        <Tooltip position='right' title="Connections">
          <IconButton
            classes="mirador__menu-btn"
            type="button"
            onClick={openRelations}
            aria-label='Open connections'
            title='Open connections'
            icon='relations'
          />
        </Tooltip>

        <Tooltip position='right' title="Annotate">
          <IconButton
            classes="mirador__menu-btn"
            type="button"
            icon='annotation'
            onClick={toggleAnnotationsPanel}
            title={annotationsPanelOpen ? 'Close annotations' : 'Open annotations'}
            aria-label={annotationsPanelOpen ? 'Close annotations' : 'Open annotations'}
          />
        </Tooltip>

        {downloadUrl && (
          <Tooltip position='right' title="Download">
            <IconButtonLink
              classes="mirador__menu-btn"
              icon='download'
              onClick={prepareDownload}
              target="_blank"
              title="Download this object"
              aria-label="Download this object"
            />
          </Tooltip>
        )}

        <Tooltip position='right' title="Image tools">
          <IconButton
            classes="mirador__menu-btn"
            type="button"
            onClick={toggleImageTools}
            aria-label={imageToolsOpen ? 'Close image tools' : 'Open image tools'}
            title={imageToolsOpen ? 'Close image tools' : 'Open image tools'}
            icon='settings'
          />
        </Tooltip>

        <div ref={moreButtonsRef} className="mirador__menu-more-btns-container">
          <IconButton
            classes="mirador__menu-btn"
            type="button"
            icon='options'
            onClick={toggleMoreButtons}
            title={annotationsPanelOpen ? 'Close more buttons menu' : 'Open more buttons menu'}
            aria-label={annotationsPanelOpen ? 'Close more buttons menu' : 'Open more buttons menu'}
          />
          <div className='mirador__menu-more-btns-wrapper'>
            <Tooltip position='bottom' title="Report an issue">
              <IconButtonLink
                icon='flag'
                href="https://americana.jcblibrary.org/report"
                title="Report an issue"
                aria-label="Report an issue"
              />
            </Tooltip>

            <Tooltip position='bottom' title="Report permissions and copyright">
              <IconButtonLink
                icon='copyright'
                href="https://americana.jcblibrary.org/permissions"
                title="Permissions and Copyright"
                aria-label="Permissions and Copyright"
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <AnnotationPanel
        windowId={windowId}
        isOpen={annotationsPanelOpen}
        toggleModal={toggleNotificationsModal}
        toggleAnnotationsPanel={toggleAnnotationsPanel}
      />

      <ImageTools
        windowId={windowId}
        isOpen={imageToolsOpen}
        toggleImageTools={toggleImageTools}
      />

      <Modal
        isOpen={notificationsModalIsOpen}
        onClose={toggleNotificationsModal}
        title='Notice'
      >
        <p>Annotations are currently only supported in single-page view mode.<br />Please switch your view and try again.</p>
      </Modal>

      <Modal
        isOpen={downloadModalIsOpen}
        onClose={toggleDownloadModal}
        title={isPreparingDownload ? 'Preparing your download...' : downloadCompleted ? 'Completed' : 'Download'}
      >
        {showManualDownloadUrl && downloadUrl && (
          <p>Failed to prepare your download. Please {downloadLink}.</p>
        )}
        {isPreparingDownload && (
          <p>
            {downloadProgress ? <>Downloading: {downloadProgress}.</> : <> This can take a moment.</>}
          </p>
        )}
        {downloadCompleted && (
          <p>The file `{downloadFileName}` has been successfully downloaded.</p>
        )}
      </Modal>
    </>
  )
}

const mapStateToProps = (state, { windowId }) => ({
  isWorkspaceAddVisible: getWorkspace(state).isWorkspaceAddVisible,
  windowViewType: getWindowViewType(state, { windowId }),
  windowsIds: getWindows(state)
})

const mapDispatchToProps = {
  setAddCompanionWindow: actions.addCompanionWindow,
  toggleAnnotationDisplay: actions.toggleAnnotationDisplay
}

const ConnectedMenuItems = connect(mapStateToProps, mapDispatchToProps)(MenuItems)

export default (container, miradorStore, windowId, initialState) => {
  const { downloadTitle, downloadUrl } = container.dataset

  render(<Provider store={miradorStore}><ConnectedMenuItems windowId={windowId} initialState={{ ...initialState, downloadTitle, downloadUrl }} /></Provider>, container)
}
