import Component from '../../../assets/scripts/modules/component'

export default class MobileNotification extends Component {
  init () {
    this.closeBtn = this.element.querySelector('.mobile-notification__close-btn')
    this.isClosed = false

    this.closeBtn && this.initCloseSupport()
  }

  initCloseSupport () {
    const closeHandler = () => {
      this.isClosed = true
      this.element.classList.add('closing')

      setTimeout(() => {
        this.element.classList.remove('closing')
        this.element.classList.add('closed')
      }, 350)
    }

    this.closeBtn.addEventListener('click', closeHandler)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.mobile-notification').forEach(element => {
  element.instance = element.instance || new MobileNotification(element)
}))
