
import Swiper from 'swiper'

import Component from '../../../assets/scripts/modules/component'
import { hydrate } from '../../../assets/scripts/utilities/hydrator'

export default class ObjectCarousel extends Component {
  init () {
    this.container = this.element.querySelector('.swiper-container')

    if (this.container) {
      const swiperOptions = {
        loop: true,
        loopAdditionalSlides: 2,
        slidesPerView: 'auto',
        spaceBetween: 20,
        loopedSlides: this.container.querySelectorAll('.swiper-slide').length,
        navigation: {
          nextEl: this.element.querySelector('.swiper-button-next'),
          prevEl: this.element.querySelector('.swiper-button-prev')
        },
        on: {
          init: () => {
            hydrate(this.element)
          }
        }
      }

      this.swiper = new Swiper(this.container, swiperOptions)

      this.initContextSupport()
    }
  }

  initContextSupport () {
    function setContext () {
      const oldIndex = activeIndex
      activeIndex = this.swiper.slides[this.swiper.activeIndex].getAttribute('data-swiper-slide-index')
      if (oldIndex >= 0) {
        items[oldIndex].removeAttribute('data-active')
      }
      items[activeIndex].setAttribute('data-enabled', '1')
      setTimeout(() => {
        if (activeIndex !== oldIndex && oldIndex >= 0) {
          items[oldIndex].removeAttribute('data-enabled')
        }
        items[activeIndex].setAttribute('data-active', '1')
      })
    }

    const items = Array.from(this.element.querySelector('.object-carousel__text-container').children)
    let activeIndex = -1

    this.swiper.on('slideChange', setContext.bind(this))
    setContext.bind(this)()
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.object-carousel').forEach(element => {
  element.instance = element.instance || new ObjectCarousel(element)
}))
