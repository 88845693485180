
import Component from '../../../assets/scripts/modules/component'

export default class ObjectRelationships extends Component {
  init () {
    //
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.object-relationships').forEach(element => {
  element.instance = element.instance || new ObjectRelationships(element)
}))
