
const initToggleBtns = (card) => {
  const optionToggle = card.querySelector('.js-card-option-toggle')
  const optionBtns = card.querySelector('.js-card-option-btns')
  if (optionToggle && optionBtns) {
    optionToggle.addEventListener('click', () => {
      optionBtns.classList.toggle('is-open')
      optionToggle.classList[optionBtns.classList.contains('is-open') ? 'add' : 'remove']('icon-button--active')
    })
  }
}

window.addEventListener('init-load', () => {
  const cards = document.querySelectorAll('.js-feature-card')
  cards.forEach(card => {
    initToggleBtns(card)
  })
})
