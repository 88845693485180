// import IconClose from '../../../../assets/icons/custom/close.svg'
import flatten from 'lodash-es/flatten'
import * as actions from 'mirador/dist/es/src/state/actions'
import {
  getAnnotations,
  getCurrentCanvas,
  getManifestTitle,
  getWindowViewType
} from 'mirador/dist/es/src/state/selectors'
import { getCanvas } from 'mirador/dist/es/src/state/selectors/canvases'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import MicroModal from '../../../../assets/scripts/plugins/micro-modal'

import React from 'react'
import AnnotationIcon from '../../../../assets/icons/custom/annotation.svg'
import useJCBLAdapterListener from '../../../../assets/scripts/utilities/react/hooks/useJCBLAdapterListener'
import { usePrevious } from '../../../../assets/scripts/utilities/react/hooks/usePrevious'
import { Button } from '../../../atoms/button/react/Button'
import JCBErrorMessage from '../../../atoms/error-message/react/JCBErrorMessage'
import IconButton from '../../../atoms/icon-button/react/IconButton'
import JCBLoader from '../../../atoms/loader/react/JCBLoader'
import Skeleton from '../../../atoms/skeleton/react/Skeleton'
import AnnotationItem from './Annotations/AnnotationItem'
import Tooltip from '../../tooltip/react/Tooltip'

const getIdAndContentOfResources = resources => {
  const annotations = {}
  resources.forEach(resource => {
    const { id, body: { value }, target: { source } } = resource
    annotations[id] = {
      id,
      content: value,
      targetId: source
    }
  })
  return Object.values(annotations)
}

const PORTRAIT = 768

const getAnnotationsOnCanvas = createSelector([getCanvas, getAnnotations], function (canvas, annotations) {
  if (!annotations || !canvas) return []
  if (!annotations[canvas.id]) return []
  return flatten(Object.values(annotations[canvas.id]))
})

const getAnnotationItemsForCanvas = createSelector([getAnnotationsOnCanvas], function (annotations) {
  return flatten(annotations.filter(r => !r.isFetching).map(r => r.json.items || []))
})

const mapStateToProps = (state, { windowId }) => {
  const canvasId = (getCurrentCanvas(state, { windowId }) || {}).id
  const getAnnotationResources = getAnnotationItemsForCanvas(state, { canvasId, windowId })

  return {
    annotations: getIdAndContentOfResources(getAnnotationResources),
    title: getManifestTitle(state, { windowId }),
    config: state.config,
    windowViewType: getWindowViewType(state, { windowId }),
    canvasId: canvasId
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return ({
    addCompanionWindow: (content, additionalProps) => {
      return dispatch(actions.addCompanionWindow(props.windowId, { content, ...additionalProps }))
    },
    removeCompanionWindow: (id) => {
      return dispatch(actions.removeCompanionWindow(props.windowId, id))
    },
    hoverAnnotation: annotationIds => {
      return dispatch(actions.hoverAnnotation(props.windowId, annotationIds))
    },
    toggleAnnotationDisplay: () => {
      return dispatch(actions.toggleAnnotationDisplay(props.windowId))
    },
    dispatchEditAnnotation: (content, additionalProps) => {
      return dispatch(actions.addCompanionWindow(props.windowId, { content, ...additionalProps }))
    },
    dispatchDeleteAnnotation: (canvasId, annotationPageId, annoPage) => {
      return dispatch(actions.receiveAnnotation(canvasId, annotationPageId, annoPage))
    }
  })
}

const AnnotationPanel = ({
  config,
  canvasId,
  dispatchEditAnnotation,
  dispatchDeleteAnnotation,
  isOpen,
  title,
  annotations,
  addCompanionWindow,
  removeCompanionWindow,
  hoverAnnotation,
  toggleAnnotationsPanel,
  toggleModal,
  windowViewType
}) => {
  const [annotationPanelId, setAnnotationPanelId] = useState(null)

  const closeAnnotationPanel = () => {
    if (annotationPanelId) {
      removeCompanionWindow(annotationPanelId)
    }
  }

  const { loading, error, createLoading, CUDError, isDeleting, isUpdating } = useJCBLAdapterListener(closeAnnotationPanel)
  const prevCUDError = usePrevious(CUDError)
  const annotationRef = useRef(null)
  const errorRef = useRef(null)

  const openAnnotationCreationPanel = async () => {
    // If not viewing object through a project
    if (!window?.JCBL_OBJECT_CONTEXT?.project_object_id) {
      const ajaxModal = document.querySelector('.ajax-modal').instance
      const hrefSplit = window.location.href.split('/')
      const slug = hrefSplit[hrefSplit.length - 2] // Get object slug from URL

      try {
        MicroModal.show('ajax-modal')
        await ajaxModal.handle('project-add-object', { getAttribute: (k) => slug })
      } catch (e) {
        console.log('ADD TO PROJECT MODAL TOGGLE ERROR ::: ', e)
      }

      return
    }

    if (windowViewType !== 'single') {
      toggleModal()
    } else {
      if (window.innerWidth < PORTRAIT) {
        toggleAnnotationsPanel()
      }
      if (annotationPanelId) {
        removeCompanionWindow(annotationPanelId)
      }
      setAnnotationPanelId(addCompanionWindow('annotationCreation', { position: 'right' }).id)
    }
  }

  const handleAnnotationHover = annotationId => {
    if (!annotations || !annotations.length || annotationId === annotationRef.current) return

    annotationRef.current = annotationId
    hoverAnnotation([annotationId])
  }

  const handleAnnotationBlur = () => {
    if (!annotations || !annotations.length) return

    annotationRef.current = null
    hoverAnnotation([])
  }

  const editAnnotation = (content, additionalProps) => {
    if (windowViewType !== 'single') {
      toggleModal()
    } else {
      if (window.innerWidth < PORTRAIT) {
        toggleAnnotationsPanel()
      }
      dispatchEditAnnotation(content, { ...additionalProps })
    }
  }

  const deleteAnnotation = async (annotationId) => {
    const adapter = config.annotation.adapter(canvasId)
    const annoPage = await adapter.delete(annotationId)

    dispatchDeleteAnnotation(canvasId, adapter.annotationPageId, annoPage)
  }

  const AnnotationPanelContent = () => {
    const AnnotationUpdateOverlay = ({ message }) => {
      return (
        <div className='mirador__annotations-panel__loading-overlay'>
          <JCBLoader message={message} />
        </div>
      )
    }

    return (
      <div>
        {annotations && annotations.length > 0 && (
          <>
            <div className="mirador__annotations-panel__annotation-count">
              <span style={{
                transform: 'scale(.75)',
                width: 12,
                height: 12
              }}>
                <AnnotationIcon />
              </span>
              <span style={{ marginLeft: 8 }}>
                {annotations.length} {annotations.length === 1 ? 'Annotation' : 'Annotations'}
              </span>
            </div>
            <Button type="button" classes='mirador__annotations-panel__add-btn' onClick={openAnnotationCreationPanel} title='Add annotation' variant='primary' iconBefore="pen" />
          </>
        )}

        {CUDError && <JCBErrorMessage ref={errorRef} message={CUDError.message} />}

        {(!annotations || !annotations.length) && !createLoading && (
          <div className='mirador__annotations-panel__annotation-empty-wrapper'>
            <h4 className="mirador__annotations-panel__annotation-empty-title">Nothing to see here. Yet.</h4>
            <p className="mirador__annotations-panel__annotation-empty-description">Start researching this object by creating your first annotation.</p>
            <Button type="button" onClick={openAnnotationCreationPanel} title='Add annotation' variant='primary' iconBefore="pen" />
          </div>
        )}

        <ol>
          {(isDeleting || isUpdating) && <AnnotationUpdateOverlay message={isDeleting ? "Hold on. We're removing your annotation from this page" : "Hold on. We're updating your annotation"} />}
          {annotations.map((annotation) => {
            return (
              <AnnotationItem
                key={annotation.id}
                hoverAnnotation={handleAnnotationHover}
                blurAnnotation={handleAnnotationBlur}
                editAnnotation={editAnnotation}
                deleteAnnotation={deleteAnnotation}
                annotation={annotation}
              >
              </AnnotationItem>
            )
          })}
          {createLoading && <AnnotationSkeleton />}
        </ol>
      </div>
    )
  }

  useEffect(() => {
    // Because of the many rerenders caused by the hover functionality, need to stop scroll behaviour if error hasn't changed
    if (!errorRef.current || prevCUDError === CUDError) return
    errorRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [errorRef.current])

  return (
    <div className={`mirador__annotations-panel ${isOpen ? 'is-open' : ''}`}>
      <div className={`mirador__annotations-panel-wrapper ${!annotations.length ? 'no-annotations' : ''}`}>
        <IconButton classes="mirador__annotations-panel__close-btn" type="button" onClick={toggleAnnotationsPanel} icon='close' />

        {window?.JCBL_OBJECT_CONTEXT?.project_object_id &&
          <div className="mirador__annotations-panel__project-selector-wrapper">
            <p className="mirador__annotations-panel__project-selector-label">Viewing project in:</p>
            {window?.JCBL_OBJECT_CONTEXT.project_name.length > 40
              ? <Tooltip position='right' title={window?.JCBL_OBJECT_CONTEXT.project_name}>
                <a className="link link--anchor" href={window?.JCBL_OBJECT_CONTEXT.project_url}>{`${window?.JCBL_OBJECT_CONTEXT.project_name.slice(0, 37)}...`}</a>
              </Tooltip>
              : <a className="link link--anchor" href={window?.JCBL_OBJECT_CONTEXT.project_url}>{window?.JCBL_OBJECT_CONTEXT.project_name}</a>
            }
            {/* <DropdownField placeholder={window?.JCBL_OBJECT_CONTEXT.project_name} variant='project-selector' disabled /> */}
            <h3 className="mirador__annotations-panel__title">{title || <Skeleton count={1.5} />}</h3>
          </div>
        }

        {error || loading
          ? <div className='mirador__annotations-panel__suspense'>
            {error ? <JCBErrorMessage message={error.message ?? 'Something went wrong while fetching your annotations'} /> : <AnnotationsSkeletonLoader />}
          </div>
          : <AnnotationPanelContent />
        }
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnotationPanel)

const AnnotationSkeleton = () => {
  return (
    <div className='mirador__annotations-panel__skeleton'>
      <Skeleton width={52} />
      <Skeleton width={250} />
      <Skeleton width={210} />
      <Skeleton width={220} />
      <Skeleton width={240} />
    </div>
  )
}

const AnnotationsSkeletonLoader = () => {
  return (
    <>
      <Skeleton width={100} style={{ marginBottom: '1.5rem' }} />
      {[0, 1, 2].map(n => <AnnotationSkeleton key={`annotation-skeleton-loader-${n}`} />)}
    </>
  )
}
