import Component from '../../../assets/scripts/modules/component'

export default class DropdownFieldComponent extends Component {
  init () {
    // Check on project-selector variant
    const isProjectSelector = this.element.dataset?.hasOwnProperty('projectSelector') // eslint-disable-line
    isProjectSelector && this.initProjectSelector()
  }

  // Options hold projectId value. Onchange we want to notify BE of a projectId change and refresh the page.
  // At a later stage we could notify the backend without refreshing the page by emitting a custom event and listening for that event
  // in the React Annotation panel to fetch the annotations linked to the new project
  initProjectSelector () {
    const selectEl = this.element.querySelector('select')
    const changeHandler = async (e) => {
      // Get new projectObjectId
      // Convention is x-xx where the first part is the projectId the object belongs to, the second part the projectObjectId
      const projectObjectId = e.target.value.split('-')[1]

      // POST to server
      try {
        const res = await fetch(
          `/api/project_objects/${projectObjectId}/set_current/`,
          {
            method: 'POST',
            headers: {
              'X-CSRFToken': window.csrftoken
            }
          }
        )

        // Refresh page on success
        if (res.ok) {
          location.reload()
        }
      } catch (e) {
        // TODO: Error handling, should page refresh and BE render dropdown field with error message?
      }
    }

    selectEl.addEventListener('change', changeHandler)
  }
}

window.addEventListener('init-load', () => {
  for (const element of document.querySelectorAll('.dropdown-field')) {
    element.instance = element.instance || new DropdownFieldComponent(element)
  }
})
