import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

class Link extends Component {
  init () {
    this.detectEventSender()
  }

  detectEventSender () {
    this.element.addEventListener('click', event => {
      const href = this.element.getAttribute('href')
      const modalEvent = this.element.dataset.modalEvent

      if (href && href.match(/^#[\w-]+:[\w-]+$/)) {
        const [component, action] = href.split('#')[1].split(':')
        const modalStackId = this.element.dataset.modalStackId
        console.log({ detail: { fromElement: this.element, modalStackId } })
        const newEvent = new CustomEvent(`${component}:${action}`, { detail: { fromElement: this.element, modalStackId } })
        window.dispatchEvent(newEvent)
        event.preventDefault()
        event.stopPropagation()
      } else if (modalEvent) {
        const [component, action] = this.element.dataset.modalEvent.split(':')
        const modalStackId = this.element.dataset.modalStackId
        const newEvent = new CustomEvent(`${component}:${action}`, { detail: { fromElement: this.element, modalStackId } })
        event.preventDefault()
        window.dispatchEvent(newEvent)
        event.stopPropagation()
      } else {
        console.log('Not prevented')
      }
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.link').forEach(element => {
  element.instance = element.instance || new Link(element)
}))

hydratorRegisterComponent('.link', Link)
