
import Swiper from 'swiper'
import { A11y, FreeMode, Navigation, Pagination, Mousewheel, Virtual } from 'swiper/modules'

import Component from '../../../assets/scripts/modules/component'
import { hydrate, hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

// Requires quite some customisations. Built for "swiper": "5.4.5"

class LazySlide extends Component {
  init () {
    this.contentContainer = this.element.querySelector('.article-carousel-slide__body')
    this.isCurrent = false
  }

  makeErrorState () {
    const node = document.createElement('div')
    node.innerText = 'Error!'
    return node
  }

  makeCurrent () {
    this.element.classList.add('article-carousel-slide--current')
    this.isCurrent = true
  }

  makeCurrentLabel () {
    const node = document.createElement('span')
    node.innerText = 'Current object'
    node.classList.add('article-carousel-slide__current-label')
    return node
  }

  setFetchUrl (url) {
    this.fetchUrl = url
  }

  async fetch () {
    const fetchUrl = this.fetchUrl
    let [json, text] = [{}, '']
    const response = await fetch(fetchUrl)

    if (response.status !== 200) {
      this.contentContainer.append(this.makeErrorState())
      return
    }

    if (fetchUrl.endsWith('.html')) {
      text = await response.text()
    } else {
      json = await response.json()
      text = json.html
    }

    const parser = new DOMParser()
    const html = parser.parseFromString(text, 'text/html')
    this.contentContainer.innerHTML = ''

    if (this.isCurrent) {
      this.contentContainer.appendChild(this.makeCurrentLabel())
    }

    for (const child of Array.from(html.body.children)) {
      this.contentContainer.appendChild(child)
    }

    hydrate(this.contentContainer)
  }
}

export default class ArticleCarousel extends Component {
  init () {
    this.container = this.element.querySelector('.swiper-container')
    this.slideFetchUrl = this.element.dataset.slideFetchUrl
    this.slideWidth = 275 // FIXME: for some reason the actual size is a bit smaller -- ideally should become 240px
    this.slideTemplate = this.element.querySelector('.article-carousel__slide-template')
    this.startIndex = parseInt(this.element.dataset.startIndex)
    this.numSlides = parseInt(this.element.dataset.numSlides)
    this.loop = this.element.dataset.loop === 'true'
    this.highlightCurrentObject = this.element.dataset.highlightCurrentObject === 'true'

    if (this.container && this.slideTemplate && this.numSlides > 0) {
      this.initSwiper()
    }
  }

  initAccessibility () {
    this.liveregion = document.createElement('div')
    this.liveregion.setAttribute('aria-live', 'polite')
    this.liveregion.setAttribute('aria-atomic', 'true')
    this.liveregion.setAttribute('class', 'liveregion visuallyhidden')
    this.container.appendChild(this.liveregion)
    this.liveregion.style.position = 'absolute'
    this.liveregion.style.opacity = '0'
  }

  initSwiper () {
    this.initAccessibility()
    this.slidesCache = {}
    this.visibleSlides = Math.ceil(window.innerWidth / this.slideWidth)
    this.slidesInContainer = this.element.getBoundingClientRect().width / this.slideWidth
    this.relativeObjectIndex = Math.floor(this.slidesInContainer / 2)
    this.slidesBefore = this.relativeObjectIndex
    this.slidesAfter = this.visibleSlides - this.relativeObjectIndex - 1
    let addSlidesTimeout = null

    const swiperOptions = {
      centeredSlides: this.loop,
      spaceBetween: 0,
      slidesPerView: 4,
      mousewheel: true,
      // {
      //     sensitivity: 4,
      //     thresholdTime: 10
      //   },
      freeMode: {
        enabled: true,
        sticky: true
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-right'),
        prevEl: this.element.querySelector('.swiper-button-left')
      },
      on: {
        init: (swiper) => {
          setTimeout(() => {
            swiper.slideTo(this.loop ? this.relativeObjectIndex : Math.max(0, this.startIndex - this.relativeObjectIndex), 0)
            hydrate(swiper.el)
            setTimeout(() => this.onResize(), 10)
            setTimeout(() => this.onResize(), 20)
          })
        },
        slideChange: () => {
          const swiper = this.swiperWrapper

          if (this.loop) {
            if (swiper.activeIndex - this.relativeObjectIndex - swiper.params.slidesPerView < -this.slidesBefore) {
              if (addSlidesTimeout) {
                clearTimeout(addSlidesTimeout)
              }

              addSlidesTimeout = setTimeout(() => {
                this.modifySlideCount(swiper.virtual.slides.length + Math.ceil(swiper.params.slidesPerView), true)
                addSlidesTimeout = null
              }, 100)
            } else if (swiper.activeIndex - this.relativeObjectIndex - swiper.params.slidesPerView < this.slidesAfter) {
              if (addSlidesTimeout) {
                clearTimeout(addSlidesTimeout)
              }

              addSlidesTimeout = setTimeout(() => {
                this.modifySlideCount(swiper.virtual.slides.length + Math.ceil(swiper.params.slidesPerView))
                addSlidesTimeout = null
              }, 100)
            }
          }
        }
      },
      virtual: {
        // Using swiper's cache won't work as it cached based on array index, but we want to modify the array's size
        // with swiper.prependSlide() and swiper.appendSlide()
        cache: false,
        enabled: true,
        addSlidesBefore: 1,
        addSlidesAfter: 1,
        renderSlide: (slide, index) => {
          // We need the relative object index
          const objectIndex = this.loop ? index - this.relativeObjectIndex : index

          // If the slide is not cached yet, build it and cache it
          if (!(objectIndex in this.slidesCache)) {
            this.slidesCache[objectIndex] = this.buildSlide(objectIndex)
          }
          // Return the cached slide
          return this.slidesCache[objectIndex]
        },
        slides: this.loop ? new Array(this.relativeObjectIndex + this.visibleSlides) : new Array(this.numSlides)
      }
    }

    Swiper.use([
      A11y,
      FreeMode,
      Navigation,
      Pagination,
      Mousewheel,
      Virtual
    ])
    this.swiperWrapper = new Swiper(this.container, swiperOptions)
    this.observe()
  }

  buildSlide (relativeObjectIndex) {
    const objectIndex = this.loop ? this.startIndex + relativeObjectIndex : relativeObjectIndex
    const realIndex = ((objectIndex % this.numSlides) + this.numSlides) % this.numSlides
    const node = this.slideTemplate.content.cloneNode(true).firstElementChild

    node.instance = new LazySlide(node)

    if (this.highlightCurrentObject && realIndex === this.startIndex) {
      node.instance.makeCurrent()
    }

    node.instance.setFetchUrl(this.slideFetchUrl.replace('SLIDE_INDEX', realIndex))
    node.instance.fetch()
    return node
  }

  observe () {
    window.addEventListener('resize', () => this.onResize())
  }

  onResize () {
    const swiper = this.swiperWrapper
    const index = swiper.activeIndex
    swiper.params.slidesPerView = window.innerWidth / this.slideWidth
    swiper.update()
    swiper.slideTo(index, 0, false)
  }

  modifySlideCount (count, prepend = false) {
    const amount = Math.max(0, count - this.swiperWrapper.virtual.slides.length)

    if (amount === 0) {
      return
    }

    if (prepend) {
      this.relativeObjectIndex += amount
      this.swiperWrapper.virtual.prependSlide(new Array(amount).fill('X'))
      this.slidesBefore += amount
    } else {
      this.swiperWrapper.virtual.appendSlide(new Array(amount).fill('X'))
      this.slidesAfter += amount
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.article-carousel').forEach(element => {
  element.instance = element.instance || new ArticleCarousel(element)
}))

hydratorRegisterComponent('.article-carousel-slide', LazySlide)
hydratorRegisterComponent('.article-carousel', ArticleCarousel)
