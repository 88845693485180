
import Component from '../../../assets/scripts/modules/component'

export default class PictureHeader extends Component {
  init () {
    this.img = this.element.querySelector('img')

    if (this.img) {
      console.log('image', this.img.src)
      this.updateMetaImage()
    }
  }

  updateMetaImage () {
    document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', this.img.src)
    document.querySelectorAll('meta[property=twitter\\:image\\:src]')[0].setAttribute('content', this.img.src)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.picture-header').forEach(element => {
  element.instance = element.instance || new PictureHeader(element)
}))
