import { useCallback, useRef, useState } from 'react'

export default function useModal (initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState)
  const toggleRef = useRef(null)

  const openModal = useCallback(() => setIsOpen(true), [setIsOpen])
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen])
  const toggleModal = useCallback(() => setIsOpen((open) => !open), [setIsOpen])

  return { toggleRef, isOpen, toggleModal, openModal, closeModal }
}
