
import Component from '../../../assets/scripts/modules/component'

const initialHeightMap = {
  small: 104,
  // small: breakpoints.isMobile() ? 195 : 114,
  medium: 250,
  large: 350
}

class CollapseComponent extends Component {
  init () {
    this.initCollapse()
  }

  initCollapse () {
    this.disclosureButton = this.element.nextElementSibling
    this.disclosureRegion = this.element.querySelector('.Collapse__Content')

    if (!this.disclosureButton || !this.disclosureRegion) return

    this.initialHeight = this.checkInitialToContentHeight() // Determintes the initial height of content

    if (!this.initialHeight) return // If height is lower than given initial height, collapse functionality should not be initialized

    this.maxHeight = this.element.dataset?.maxHeight && +this.element.dataset.maxHeight
    this.maskOverflow = !!this.element.dataset?.maskOverflow

    this.disclosureButton.addEventListener('click', () => this.toggleRegion())
    window.addEventListener('resize', () => this.handleResize())
    this.setTabIndexes(this.disclosureRegion, true)

    // Mask overflow boolean creates smooth fades at the top and bottom of the scroll container
    if (this.maskOverflow) {
      this.element.addEventListener('scroll', () => this.handleScroll())
    }
  }

  toggleRegion () {
    const isCollapsing = (this.disclosureButton.getAttribute('aria-expanded') === 'true')

    this.disclosureRegion.setAttribute('aria-hidden', isCollapsing)
    this.disclosureButton.setAttribute('aria-expanded', !isCollapsing)
    this.element.classList.toggle('Collapse--scrollable', !!this.maxHeight && !isCollapsing)
    this.element.classList.toggle('masked-overflow', !!this.maxHeight && !isCollapsing && this.maskOverflow)

    isCollapsing ? this.collapseContent() : this.expandContent()

    this.setTabIndexes(this.disclosureRegion, isCollapsing)
  }

  // Scroll listener for masked overflow
  handleScroll () {
    const { scrollTop, scrollHeight, clientHeight } = this.element

    const setStyle = (property, value) => {
      this.element.style.setProperty(property, value)
    }

    // Gradually set fade when scrolling from top
    if (scrollTop <= 32) {
      setStyle('--mask-height-top', `${scrollTop * 1.5}px`)
    }

    // Gradually set fade when scrolling near edge of container
    if (scrollTop + clientHeight >= scrollHeight - 32) {
      setStyle('--mask-height-bottom', `${(scrollHeight - scrollTop - clientHeight) * 1.5}px`)
    }
  }

  // On window resize, re-calculate max-height since content container will have resized as well
  handleResize () {
    const isOpen = this.disclosureButton.getAttribute('aria-expanded') === 'true'
    this.maxHeight = this.element.dataset?.maxHeight ? +this.element.dataset.maxHeight : this.disclosureRegion.getBoundingClientRect().height
    isOpen ? this.expandContent() : this.collapseContent()
  }

  expandContent () {
    this.maskOverflow && this.handleScroll()
    this.setHeight(this.maxHeight || this.disclosureRegion.getBoundingClientRect().height)
    this.setButtonText(this.element.dataset.buttonCollapseTitle)
  }

  collapseContent () {
    this.setHeight(this.initialHeight)
    this.setButtonText(this.element.dataset.buttonTitle)
    this.element.scrollTop = 0
  }

  setTabIndexes (region, isCollapsed) {
    const focusableElements = [...region.querySelectorAll('a[href], button')]
    focusableElements.forEach(element => isCollapsed ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex'))
  }

  setHeight (height = 0) {
    this.element.style.height = `${Math.round(height)}px`
  }

  setButtonText (text) {
    const buttonSpan = this.disclosureButton.querySelector('.button__span')
    buttonSpan.innerHTML = text
  }

  // Checks scenario's where content is smaller than initial height
  checkInitialToContentHeight () {
    const key = this.element.dataset?.initialHeight
    const initialHeightValue = key ? initialHeightMap[key] : null

    // If no initial height is given, assume content is fully collapsed
    if (!key) {
      return 0
    }

    // If content height is larger than initial height value, collapse until initial height value
    const { height } = this.disclosureRegion.getBoundingClientRect()
    if (height > initialHeightValue
    // || breakpoints.isMobile()
    ) { // There is a bug in mobile where the line-clamp CSS rule causes the content height to always be smaller than the initial height, so for now always show button
      return initialHeightValue
    }

    console.log('CONTENT IS SMALLER THAN COLLAPSE')

    // If content is smaller than initial height value, remove collapse button and show full content equal to the height of the element
    // if (!breakpoints.isMobile()) {
    // }
    this.disclosureButton.style.display = 'none'
    this.element.classList.toggle('initial-small', false)
    this.element.classList.toggle('initial-medium', false)
    this.element.classList.toggle('initial-large', false)

    return height
  }
}

window.CollapseComponent = CollapseComponent

window.addEventListener('init-load', () => document.querySelectorAll('.Collapse').forEach(element => {
  element.instance = element.instance || new CollapseComponent(element)
}))
